/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import WorkflowsEdgeGenerator from 'embercom/objects/workflows/graph-editor/edge/generator';
import containerLookup from 'embercom/lib/container-lookup';
import { assert, debug } from '@ember/debug';
import { schedule } from '@ember/runloop';
import { captureException } from 'embercom/lib/sentry';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import Coordinates from 'graph-editor/models/graph-editor/coordinates';
import type Graph from 'graph-editor/models/graph-editor/graph';
import type Edge from 'embercom/models/operator/visual-builder/edge';
import StaticEdge from 'embercom/models/operator/visual-builder/static-edge';
import type Group from 'embercom/models/operator/visual-builder/group';
import { type default as _Node } from 'graph-editor/models/graph-editor/node';
import { type ConnectionPointInputs } from 'graph-editor/models/graph-editor/connection-point';
import type MutableArray from '@ember/array/mutable';
import type Bounds from 'graph-editor/models/graph-editor/bounds';
// eslint-disable-next-line no-restricted-imports
import { getApp } from 'embercom/lib/container-lookup';
import GraphEditorNode from 'graph-editor/models/graph-editor/node';
import GraphEditorEdge from 'graph-editor/models/graph-editor/edge';

const NODE_HEIGHT = 47;
const NODE_WIDTH = 313;

const TRIGGER_NODE_WIDTH = 285;

const DEFAULT_GRID_HEIGHT = 4000;
const DEFAULT_GRID_WIDTH = 6000;
const GRID_MARGIN_LEFT = DEFAULT_GRID_WIDTH;
const GRID_MARGIN_TOP = DEFAULT_GRID_HEIGHT;
const GRID_SPACING = 16;
const GRID_BOUNDARY_BUFFER = 1000;

const OUTWARD_CONNECTION_POINT_ELEMENT_X_OFFSET = 16;
export const INWARD_CONNECTION_POINT_NODE_X_OFFSET = 8;
export const SPACING_BETWEEN_NODES = 128;

const DEFAULT_COORDINATES = {
  x: 100,
  y: 100,
};

const DEFAULT_COORDINATES_FULL_SCREEN = {
  x: 100 + TRIGGER_NODE_WIDTH + SPACING_BETWEEN_NODES,
  y: 100,
};

const DEFAULT_OUTWARD_CONNECTION_POINT: ConnectionPointInputs = {
  x: 0,
  y: NODE_HEIGHT / 2,
  alignment: 'right',
  direction: 'outwards',
};
export const DEFAULT_INWARD_CONNECTION_POINT: ConnectionPointInputs = {
  x: -INWARD_CONNECTION_POINT_NODE_X_OFFSET,
  y: NODE_HEIGHT / 2,
  alignment: 'left',
  direction: 'inwards',
};

function roundUpToNearest1000(num: number) {
  return Math.ceil(num / 1000) * 1000;
}

type GraphLayoutParams = {
  graph: Graph;
  workflow: Workflow;
  isViewOnly?: boolean;
  hasTriggerInfoPanel?: boolean;
};

export type Node = _Node<Group>;

export default class GraphLayout {
  app: any;
  graph: Graph<Group, Edge>;
  workflow: Workflow;
  isViewMode: boolean;
  connectionPointElements: WeakMap<ConnectionPoint, HTMLElement>;
  currentLayoutNodeGrid: Node[][] = [];
  defaultCoordinates: { x: number; y: number };

  constructor(params: GraphLayoutParams) {
    this.app = getApp();
    this.graph = params.graph;
    this.workflow = params.workflow;
    this.isViewMode = params.isViewOnly || false;
    this.connectionPointElements = new WeakMap();
    this.defaultCoordinates = params.hasTriggerInfoPanel
      ? DEFAULT_COORDINATES_FULL_SCREEN
      : DEFAULT_COORDINATES;

    assert('[GraphLayout] You must specify a graph', this.graph);
    assert('[GraphLayout] You must specify a workflow', this.workflow);

    this._setDefaultSettings();
  }

  _setDefaultSettings() {
    this.graph.settings.scale.zoomFactor = 0.2;
    this.graph.settings.grid.width = DEFAULT_GRID_WIDTH;
    this.graph.settings.grid.height = DEFAULT_GRID_HEIGHT;
    this.graph.settings.grid.spacing = GRID_SPACING;
    this.graph.settings.node.defaultWidth = NODE_WIDTH;
    this.graph.settings.node.defaultHeight = NODE_HEIGHT;
    this.graph.settings.node.defaultSpacing = NODE_WIDTH * 2;
  }

  draw() {
    this.workflow.groups.forEach((group) => {
      // draw all nodes to calculate bounds
      let position = new Coordinates(0, 0);
      this._addNode(group, position);
    });

    this.workflow.groups.forEach((group) => {
      let outwardConnectionPoints: ConnectionPoint[] = this._getConnectionPointsForGroup(group);

      outwardConnectionPoints.forEach((connectionPoint) => {
        if (connectionPoint.isConnected) {
          try {
            this.addEdge(connectionPoint.edge!);
          } catch (e) {
            captureException(e, {
              extra: {
                connectionPoint: connectionPoint.serialize(),
                edge: connectionPoint.edge?.serialize(),
                workflow: this.workflow.serialize({ sanitize: true }),
              },
              tags: {
                component: 'visual-builder',
              },
            });
            throw e;
          }
        }
      });
    });

    schedule('afterRender', this, () => {
      this._positionNodes();
      this._refreshConnectionPoints();
    });
  }

  setConnectionPointElement(connectionPoint: ConnectionPoint, element: HTMLElement) {
    this.connectionPointElements.set(connectionPoint, element);
  }

  removeConnectionPointElement(connectionPoint: ConnectionPoint) {
    this.connectionPointElements.delete(connectionPoint);
  }

  updateConnectionPointsForNode(node: Node) {
    schedule('afterRender', this, () => {
      this._refreshConnectionPointsForNode(node);
    });
  }

  repositionNodes() {
    schedule('afterRender', this, () => {
      this._positionNodes();
    });
  }

  repositionNodesInColumn(columnIndex: number) {
    schedule('afterRender', this, () => {
      this._positionNodesInColumn(this.currentLayoutNodeGrid[columnIndex]);
    });
  }

  createStaticNodeAndEdge(nextNode: Node) {
    let staticInfoPanelNode = new GraphEditorNode(
      new Coordinates(DEFAULT_COORDINATES.x, DEFAULT_COORDINATES.y),
      this.graph,
    );
    staticInfoPanelNode.addConnectionPoint({ ...DEFAULT_OUTWARD_CONNECTION_POINT, y: 120 });
    let staticInfoPanelEdge = new GraphEditorEdge(staticInfoPanelNode, nextNode, new StaticEdge());
    staticInfoPanelEdge.generator = new WorkflowsEdgeGenerator(staticInfoPanelEdge);

    return { staticInfoPanelNode, staticInfoPanelEdge };
  }

  connectNewNode(newGroup: Group, edgeModel: Edge, connectionPoint?: ConnectionPoint) {
    let predecessorNode = this.graph.nodeForDataObject(edgeModel.fromGroup);

    if (connectionPoint) {
      predecessorNode = this.graph.nodeForDataObject(connectionPoint.step.group);
    }

    if (!predecessorNode) {
      throw new Error('The predecessorNode must be present in the graph');
    }

    let successorNodeDefaultPosition = new Coordinates(
      this._calculateXPositionForColumn(newGroup.layoutColumnIndex),
      0,
    );
    let successorNode = this._addNode(newGroup, successorNodeDefaultPosition);
    if (this.currentLayoutNodeGrid[newGroup.layoutColumnIndex]) {
      this.currentLayoutNodeGrid[newGroup.layoutColumnIndex].push(successorNode);
    } else {
      this.currentLayoutNodeGrid.push([successorNode]);
    }
    let edge = this.addEdge(edgeModel);

    this.repositionNodesInColumn(newGroup.layoutColumnIndex);

    this.updateConnectionPointsForNode(edge.predecessor);
    schedule('afterRender', this, () => {
      this.travelToNode(successorNode);
    });

    return successorNode;
  }

  findNodeForGroup(group: Group): Node | undefined {
    return this.graph.nodeForDataObject(group);
  }

  removeNode(group: Group) {
    let columnIndex = group.layoutColumnIndex;
    let node = this.graph.nodeForDataObject(group);
    if (node) {
      // Remove the node from the cached layout grid array
      let rowPositionForNodeInLayout = this.currentLayoutNodeGrid[columnIndex].indexOf(node);
      this.currentLayoutNodeGrid[columnIndex].splice(rowPositionForNodeInLayout, 1);

      // Remove the node from the graph editor models + UI
      this.graph.deleteNode(node);
    }
    this.repositionNodesInColumn(columnIndex);
  }

  removeEdge(edge: Edge) {
    this._removeEdge(edge);
  }

  updateGridDimensions(nodes: MutableArray<Node> = []) {
    let { height, width } = this.graph.settings.grid;

    // Calculate if we should update grid height
    let currentGraphBottom = Math.max(...(nodes.mapBy('bounds.bottom') as Bounds['bottom'][]));
    let maxAllowedGraphBottom = height + GRID_MARGIN_TOP - GRID_BOUNDARY_BUFFER;
    let shouldUpdateGridHeight = currentGraphBottom > maxAllowedGraphBottom;

    // Calculate if we should update grid width
    let currentGraphRight = Math.max(...(nodes.mapBy('bounds.right') as Bounds['right'][]));
    let maxAllowedGraphRight = width + GRID_MARGIN_LEFT - GRID_BOUNDARY_BUFFER;
    let shouldUpdateGridWidth = currentGraphRight > maxAllowedGraphRight;

    if (shouldUpdateGridHeight || shouldUpdateGridWidth) {
      let newHeight = shouldUpdateGridHeight ? roundUpToNearest1000(currentGraphBottom) : height;
      let newWidth = shouldUpdateGridWidth ? roundUpToNearest1000(currentGraphRight) : width;
      this._updateGridSettings({ height: newHeight, width: newWidth });

      let { is_live, workflow_instance_entity_id, workflow_instance_entity_type } =
        this.workflow?.analyticsData ?? {};

      let intercomEventService = containerLookup('service:intercomEventService');
      intercomEventService.trackAnalyticsEvent({
        action: 'resized',
        object: 'visual_builder_grid',
        // additional metadata
        workflow_instance_entity_id,
        workflow_instance_entity_type,
        is_live,
        height: this.graph.settings.grid.height,
        width: this.graph.settings.grid.height,
        total_nodes: this.graph.nodes.length,
      });
    }
  }

  travelToNode(node: Node) {
    if (!this.graph.wrapper) {
      return;
    }

    // update viewportBounds as the container may have scrolled since last calculation
    this.graph.state.updateWrapperBoundingRect(this.graph.wrapper);

    // focus node if not yet contained within the viewport
    if (!node.bounds.containedWithin(this.graph.state.viewportBounds)) {
      this.graph.focusNode(node);
    }

    // show taveled-to node as the only selected node
    this.graph.state.selectedNodes.clear();
    this.graph.state.addSelectedNode(node);
  }

  travelToNodeOrConnectionPoint(node: Node, connectionPoint?: ConnectionPoint) {
    if (!this.graph.wrapper) {
      return;
    }

    // update viewportBounds as the container may have scrolled since last calculation
    this.graph.state.updateWrapperBoundingRect(this.graph.wrapper);

    let connectionPointFromNode = node.connectionPoints.find(
      (nodeConnectionPoint) => nodeConnectionPoint.dataObject === connectionPoint,
    );

    if (connectionPoint && connectionPointFromNode) {
      this._positionConnectionPoint(node, connectionPoint);

      // focus on node connection point
      let yOffset = connectionPointFromNode.y;
      this.graph.focusNode(node, yOffset);
    } else {
      this.graph.focusNode(node);
    }
  }

  _addNode(group: Group, position: Coordinates) {
    return this.graph.addNode({
      position,
      connectionPoints: [DEFAULT_INWARD_CONNECTION_POINT],
      dataObject: group,
    });
  }

  addEdge(edgeModel: Edge) {
    let predecessor = this.graph.nodeForDataObject(edgeModel.fromGroup);

    if (!predecessor) {
      throw new Error('The group the edge is coming from must be present in the graph');
    }

    let edge = this.graph.addEdge({
      predecessor,
      successor: this.graph.nodeForDataObject(edgeModel.toGroup),
      dataObject: edgeModel,
    });

    edge.generator = new WorkflowsEdgeGenerator(edge);
    return edge;
  }

  _removeEdge(edge: Edge) {
    let graphEditorEdge = this.graph.edgeForDataObject(edge);
    if (graphEditorEdge) {
      this.graph.deleteEdge(graphEditorEdge);
    }
  }

  _positionNodes() {
    let repositionedNodes: Node[] = [];
    let { groupsByColumnIndex, columnPositions, rowPositions } = this._buildGridPositions();
    this.currentLayoutNodeGrid = groupsByColumnIndex.map((groups, columnIndex) => {
      return groups.map((group) => {
        group.layoutColumnIndex = columnIndex;
        return this.graph.nodeForDataObject(group);
      }) as Node[];
    });

    groupsByColumnIndex.forEach((groups, columnIndex) => {
      let previousNode: Node | undefined; // previous node in column

      groups.forEach((group, rowIndex) => {
        group.layoutColumnIndex = columnIndex;
        let node = this.graph.nodeForDataObject(group);

        if (!node) {
          throw new Error('Node must be present in the graph');
        }

        let defaultPosition = new Coordinates(columnPositions[columnIndex], rowPositions[rowIndex]);
        let position = this._buildPositionForNode(defaultPosition, previousNode);
        let nodePositionChanged = node.position.x !== position.x || node.position.y !== position.y;

        if (nodePositionChanged) {
          node.position.x = position.x;
          node.position.y = position.y;
          repositionedNodes.push(node);
        }

        previousNode = node;
      });
    });

    this._calculateNodeBounds(repositionedNodes);
  }

  _positionNodesInColumn(nodes: Node[]) {
    let repositionedNodes: Node[] = [];
    let rowPositions = this._buildRowPositions(nodes);

    let previousNode: Node | undefined;

    nodes.forEach((node, rowIndex) => {
      let defaultPosition = new Coordinates(node.position.x, rowPositions[rowIndex]);
      let position = this._buildPositionForNode(defaultPosition, previousNode);
      let nodeRowPositionChanged = node.position.y !== position.y;

      if (nodeRowPositionChanged) {
        node.position.y = position.y;
        repositionedNodes.push(node);
      }

      previousNode = node;
    });

    this._calculateNodeBounds(repositionedNodes);
  }

  _buildPositionForNode(defaultPosition: Coordinates, previousNode?: Node): Coordinates {
    if (!previousNode) {
      return defaultPosition;
    }

    // eagerly calculate previousNode bottom since previousNode.calculateBounds() may not have been called
    let eagerlyCalculatedNodeBottom = previousNode.position.y + previousNode.bounds.height;
    let verticalSpacingBetweenNodes = 2 * this.graph.settings.grid.spacing;

    return new Coordinates(
      defaultPosition.x,
      eagerlyCalculatedNodeBottom + verticalSpacingBetweenNodes,
    );
  }

  _buildGridPositions() {
    let startingGroup = this.workflow.groups.find((group) => group.isStart);

    if (!startingGroup) {
      throw new Error('There must be a starting group present');
    }

    let groupsByColumnIndex: Group[][] = [];
    let visitedGroups = new Set<Group>();
    this._segregrateGroupsByDepth(startingGroup, 0, groupsByColumnIndex, visitedGroups);

    // Add orphan groups to a new column at the end
    let orphanGroups = this.workflow.groups.filter((group) => !visitedGroups.has(group));
    groupsByColumnIndex.push(orphanGroups);

    // Calculate the max heights for each row, max widths for each column, and number of outward connection points per column
    let maxHeightForRows: { [key: number]: number } = {};
    let maxWidthForColumns: { [key: number]: number } = {};
    let numOutwardConnectionPointsForColumns: { [key: number]: number } = {};
    groupsByColumnIndex.forEach((groups, columnIndex) => {
      groups.forEach((group, rowIndex) => {
        let node = this.graph.nodeForDataObject(group);

        if (!node) {
          throw new Error('The group must be present in the graph');
        }

        maxHeightForRows[rowIndex] = Math.max(maxHeightForRows[rowIndex] ?? 0, node.bounds.height);

        maxWidthForColumns[columnIndex] = Math.max(
          maxWidthForColumns[columnIndex] ?? 0,
          node.bounds.width,
        );

        let numOutwardConnectionPoints = group.steps.reduce(
          (num, step) => num + Number(step.outwardConnectionPoints.length),
          0,
        );
        numOutwardConnectionPointsForColumns[columnIndex] =
          (numOutwardConnectionPointsForColumns[columnIndex] ?? 0) + numOutwardConnectionPoints;
      });
    });

    // Calcute starting positions for columns
    let columnPositions: { [key: number]: number } = {};
    for (let columnIndex = 0; columnIndex < groupsByColumnIndex.length; columnIndex++) {
      if (columnIndex === 0) {
        columnPositions[0] = this.defaultCoordinates.x;
      } else {
        columnPositions[columnIndex] =
          columnPositions[columnIndex - 1] +
          maxWidthForColumns[columnIndex - 1] +
          SPACING_BETWEEN_NODES;
      }
    }

    // Calculate starting positions for rows
    let rowPositions: { [key: number]: number } = {};
    let numRows = groupsByColumnIndex.reduce(
      (maxRows, currentColumn) => (maxRows > currentColumn.length ? maxRows : currentColumn.length),
      0,
    );
    for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
      if (rowIndex === 0) {
        rowPositions[0] = this.defaultCoordinates.y;
      } else {
        rowPositions[rowIndex] =
          rowPositions[rowIndex - 1] +
          maxHeightForRows[rowIndex - 1] +
          this.graph.settings.grid.spacing;
      }
    }

    return { groupsByColumnIndex, columnPositions, rowPositions };
  }

  _calculateXPositionForColumn(columnIndex: number) {
    let spacePerColumn = NODE_WIDTH + SPACING_BETWEEN_NODES;
    return this.defaultCoordinates.x + spacePerColumn * columnIndex;
  }

  _buildRowPositions(nodes: Node[]): { [key: number]: number } {
    let rowPositions: { [key: number]: number } = {};

    nodes.forEach((node, rowIndex) => {
      if (rowIndex === 0) {
        rowPositions[rowIndex] = this.defaultCoordinates.y;
      } else {
        rowPositions[rowIndex] =
          rowPositions[rowIndex - 1] + node.bounds.height + this.graph.settings.grid.spacing;
      }
    });

    return rowPositions;
  }

  _calculateNodeBounds(nodes: Node[] = []) {
    nodes.forEach((n) => n.calculateBounds());
    this.updateGridDimensions(nodes);
  }

  _segregrateGroupsByDepth(
    group: Group,
    currentDepth: number,
    groupsByColumnIndex: Group[][],
    visitedGroups: Set<Group>,
  ) {
    if (visitedGroups.has(group)) {
      return;
    }

    if (groupsByColumnIndex.length <= currentDepth) {
      groupsByColumnIndex.push([group]);
    } else {
      groupsByColumnIndex[currentDepth].push(group);
    }

    visitedGroups.add(group);

    let outwardConnectionPoints: ConnectionPoint[] = this._getConnectionPointsForGroup(group);

    outwardConnectionPoints.forEach((connectionPoint) => {
      if (connectionPoint.isConnected) {
        this._segregrateGroupsByDepth(
          connectionPoint.edge!.toGroup,
          currentDepth + 1,
          groupsByColumnIndex,
          visitedGroups,
        );
      }
    });
  }

  _getConnectionPointsForGroup(group: Group): ConnectionPoint[] {
    let outwardConnectionPoints: ConnectionPoint[] = [];

    //check if group has a group level outward connection point
    if (group.outwardConnectionPoint) {
      outwardConnectionPoints.pushObject(group.outwardConnectionPoint);
    }

    //check if any of the steps have outward connection points
    group.steps.forEach((step) => {
      step.outwardConnectionPoints.forEach((connectionPoint) => {
        outwardConnectionPoints.push(connectionPoint);
      });
    });

    return outwardConnectionPoints;
  }

  _refreshConnectionPoints() {
    this.graph.nodes.forEach((node) => this._refreshConnectionPointsForNode(node));
  }

  _refreshConnectionPointsForNode(node: Node) {
    let groupOutwardConnectionPoints = node.dataObject?.findAllOutwardConnectionPoints() || [];
    this._deleteObsoleteConnectionPoints(node, groupOutwardConnectionPoints);
    groupOutwardConnectionPoints.forEach((point) => this._positionConnectionPoint(node, point));
  }

  _deleteObsoleteConnectionPoints(node: Node, groupOutwardConnectionPoints: ConnectionPoint[]) {
    let nodeOutwardConnectionPoints = node.connectionPoints.filter(
      (point) => point.direction === 'outwards',
    );
    let pointsToDelete = nodeOutwardConnectionPoints.filter(
      (np) => !groupOutwardConnectionPoints.includes(np.dataObject),
    );

    pointsToDelete.forEach((nodeConnectionPoint) => {
      // Delete any edge that may be originating from this connection point first
      let originatingEdge = this.graph.edges.find(
        (edge) => edge.dataObject?.outwardConnectionPoint === nodeConnectionPoint.dataObject,
      );
      if (originatingEdge) {
        debug(`Found edge originating from id=${nodeConnectionPoint.dataObject}. Deleting it`);
        this.graph.deleteEdge(originatingEdge);
      }
      debug(`Deleting obsolete connection point with id=${nodeConnectionPoint.dataObject}`);
      node.removeConnectionPoint(nodeConnectionPoint);
    });
  }

  _positionConnectionPoint(node: Node, connectionPoint: ConnectionPoint) {
    let elementForConnectionPoint = this.connectionPointElements.get(connectionPoint);

    let { x, y } = DEFAULT_OUTWARD_CONNECTION_POINT;

    if (elementForConnectionPoint) {
      [x, y] = this._offsetsFromNodeTopRight(node, elementForConnectionPoint);
    } else {
      debug(`Connection point element for id=${connectionPoint} not found`);
    }

    let nodeConnectionPoint = node.connectionPoints.find(
      (point) => point.direction === 'outwards' && point.dataObject === connectionPoint,
    );

    if (nodeConnectionPoint) {
      if (nodeConnectionPoint.x !== x || nodeConnectionPoint.y !== y) {
        debug(
          `Updating existing connection point for id=${connectionPoint}. New position ${x}, ${y}`,
        );
        nodeConnectionPoint.x = x;
        nodeConnectionPoint.y = y;
      } else {
        debug(`No change to position for connection point id=${connectionPoint}`);
      }
    } else {
      debug(
        `No connection point found for id=${connectionPoint}. Creating new one at position ${x}, ${y}`,
      );

      node.pushConnectionPoint({
        x,
        y,
        alignment: 'right',
        direction: 'outwards',
        dataObject: connectionPoint,
      });
    }
  }

  _offsetsFromNodeTopRight(node: Node, element: HTMLElement) {
    let scale = this.graph.state.scale;
    let elementRect = element.getBoundingClientRect();
    let parentRect = node.element?.getBoundingClientRect();

    if (!parentRect) {
      throw new Error('Element must be defined for node');
    }

    let nodeTopRight = {
      x: parentRect.right,
      y: parentRect.top,
    };

    let connectorCenter = {
      x: elementRect.right + OUTWARD_CONNECTION_POINT_ELEMENT_X_OFFSET,
      y: elementRect.top + elementRect.height / 2,
    };

    let x = Math.round((connectorCenter.x - nodeTopRight.x) / scale);
    let y = Math.round((connectorCenter.y - nodeTopRight.y) / scale);

    return [x, y];
  }

  _updateGridSettings(gridSettings = {}) {
    Object.assign(this.graph.settings.grid, gridSettings);

    // refresh viewport so new grid settings take effect
    this._refreshGraph();
  }

  _refreshGraph() {
    let { state } = this.graph;
    state.updateTransformOriginForCoordinates(state.viewportBounds.centerPoint);
  }
}
