/* RESPONSIBLE TEAM: team-phone */
import StepConfig, { type StepConfigParams } from '../step';
import type FinCustom from 'embercom/models/operator/visual-builder/step/fin-custom';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import type Store from '@ember-data/store';

export default class FinCustomConfiguration extends StepConfig {
  declare step: FinCustom;
  protected attributeService: any;
  store: Store;
  app: any;

  constructor(
    params: Omit<StepConfigParams, 'component'> & {
      component?: string;
      app: any;
      attributeService: any;
      store: Store;
    },
  ) {
    super({
      component: 'workflows/graph-editor/node-items/steps/fin-custom',
      ...params,
    });
    this.app = params.app;
    this.attributeService = params.attributeService;
    this.store = params.store;
  }

  localAttributeGroupListExcludingOutputParams() {
    let localAttributeGroupList = this.step.editorConfig?.localAttributeGroupList;
    let localVariables = localAttributeGroupList?.find((group) => {
      return (
        group.heading ===
        this.intl.t('operator.workflows.visual-builder.attribute-collector.local-variables')
      );
    });

    if (localVariables) {
      localVariables.attributes = localVariables.attributes.filter(
        (attribute: any) => !this.step.finOutputParams?.toArray().includes(attribute),
      );
    }

    return localAttributeGroupList;
  }

  deleteStep() {
    this.step.finOutputParams.toArray().forEach((attribute) => {
      this.editorState.deleteLocalVariable(attribute);
    });
    super.deleteStep();
  }

  get alignment() {
    return 'left' as const;
  }

  get attributeInfoResolver() {
    return new AttributeInfoResolver({
      attributes:
        this.step.editorConfig?.runtimeMatchingTargetingAttributes
          .map((group) => group.attributes)
          .flat() ?? this.attributeService.userEditorMetadataAndConversationAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: false,
      includeCustomObjectAttributes: true,
      includeFileAttributes: this.step.appService.app.canUseFinCustomImageRecognition,
      localAttributes: this.step.isV3
        ? this.step.editorConfig?.localAttributeGroupList
        : this.localAttributeGroupListExcludingOutputParams(),
      allLocalAttributes: this.step.editorConfig?.allLocalAttributeGroupList,
      customActions: this.finCustomActionsGroupList(),
    });
  }

  finCustomActionsGroupList() {
    return [
      {
        heading: this.intl.t(
          'operator.workflows.visual-builder.fin-custom-settings.configure.custom-actions',
        ),
        attributes: this.store
          .peekAll('workflow-connector/insertable-action')
          .filter((action) => action.state === 'live' && !action.isTemplate)
          .filter((action) => {
            return action.usage === 'fin' || this.app.canUseConsolidatedActions;
          })
          .map((action) => {
            return {
              identifier: `workflow_connector.${action.id}`,
              templatable_identifier: `workflow_connector.${action.id}`,
              composer_name: this.step.isV3
                ? action.name
                : this.intl.t(
                    'operator.workflows.visual-builder.fin-custom-settings.configure.prompt.custom-action',
                    {
                      action_name: action.name,
                    },
                  ),
              name: action.name,
              type: 'string',
              icon: 'webhook',
              noFallback: true,
            };
          }),
      },
    ];
  }
}
