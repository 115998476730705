/* RESPONSIBLE TEAM: team-tickets-1 */

export interface AwayStatusReasonWireFormat {
  readonly id: string;
  readonly label: string;
  readonly emoji: string;
  readonly order: number;
  readonly associated_to_phone_status?: string;
  readonly deleted?: boolean;
}

export class AwayStatusReason {
  id: string;
  label: string;
  emoji: string;
  order: number;
  associatedToPhoneStatus?: string;
  deleted?: boolean;

  constructor(inputs: {
    id: string;
    label: string;
    emoji: string;
    order: number;
    associatedToPhoneStatus?: string;
    deleted?: boolean;
  }) {
    this.id = inputs.id;
    this.label = inputs.label;
    this.emoji = inputs.emoji;
    this.order = inputs.order;
    this.associatedToPhoneStatus = inputs.associatedToPhoneStatus;
    this.deleted = inputs.deleted;
  }

  static deserialize(json: AwayStatusReasonWireFormat): AwayStatusReason {
    return new AwayStatusReason({
      id: json.id,
      label: json.label,
      emoji: json.emoji,
      order: json.order,
      associatedToPhoneStatus: json.associated_to_phone_status,
      deleted: json.deleted,
    });
  }
}
