/* RESPONSIBLE TEAM: team-help-desk-experience */
/*
 * A PartGroup represents a grouping of RenderableParts. Rather than render each part one-by-one
 * in the Conversation Stream, we group them up by category (e.g. user content, admin content, bot content etc)
 * and render each part of that group in a more condensed fashion (e.g. only rendering the avatar for the first
 * part of the group).
 *
 * If you need to change the logic for how we group parts within the stream, the `groupParts` method
 * is where you should look.
 */

import type RenderablePart from '../renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type Conversation from '../conversation';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import BotComment from 'embercom/objects/inbox/renderable/bot-comment';
import FinAnswer from 'embercom/objects/inbox/renderable/fin-answer';
import { Channel } from 'embercom/models/data/inbox/channels';

export enum PartGroupCategory {
  UserContent,
  UserEmailContent,
  Event,
  TeammateContent,
  TeammateNoteContent,
  BotContent,
  OutboundChat,
  OutboundEmail,
  DuplicateConversationNote,
  Unknown,
  Article,
  UnknownParticipantForEmailConversationNote,
  OutboundPost,
  OutboundSms,
  OutboundWhatsapp,
  UserRating,
  QuickReplyOptions,
  TicketEvent,
  CallSummary,
  TeammateEmailReply,
  BotEmailReply,
  SideConversationEvent,
  MergedConversationEvent,
  MergedUserContent,
  MergedSecondaryConversationEvent,
  MergedPrimaryConversationEvent,
  GenericHandoff,
}

export const partCategoryMapping = (part: RenderablePart) => {
  switch (part.renderableType) {
    case RenderableType.Unknown:
      return PartGroupCategory.Unknown;
    case RenderableType.AdminNote:
      return PartGroupCategory.TeammateNoteContent;
    case RenderableType.BotNote:
      return PartGroupCategory.BotContent;
    case RenderableType.BotComment:
      if (
        part.renderableData instanceof BotComment &&
        part.renderableData.channel === Channel.Email
      ) {
        return PartGroupCategory.BotEmailReply;
      }
      return PartGroupCategory.BotContent;
    case RenderableType.UserComment:
      return PartGroupCategory.UserContent;
    case RenderableType.AdminComment:
      if (
        part.renderableData instanceof AdminComment &&
        part.renderableData.channel === Channel.Email
      ) {
        return PartGroupCategory.TeammateEmailReply;
      } else {
        return PartGroupCategory.TeammateContent;
      }
    case RenderableType.StateChange:
      return PartGroupCategory.Event;
    case RenderableType.Assignment:
      return PartGroupCategory.Event;
    case RenderableType.QuickReplyOptions:
      return PartGroupCategory.QuickReplyOptions;
    case RenderableType.QuickReplyResponse:
      return PartGroupCategory.UserContent;
    case RenderableType.AttributeCollector:
      return PartGroupCategory.Event;
    case RenderableType.UserEmailComment:
      return PartGroupCategory.UserEmailContent;
    case RenderableType.AttributeCollected:
      return PartGroupCategory.Event;
    case RenderableType.ConversationAttributeUpdated:
      return PartGroupCategory.Event;
    case RenderableType.TitleChanged:
      return PartGroupCategory.Event;
    case RenderableType.ConversationSlaAppliedByWorkflow:
      return PartGroupCategory.Event;
    case RenderableType.ConversationSlaAppliedByRule:
      return PartGroupCategory.Event;
    case RenderableType.ConversationSlaRemoved:
      return PartGroupCategory.Event;
    case RenderableType.ConversationSlaPaused:
      return PartGroupCategory.Event;
    case RenderableType.ConversationSlaUnpaused:
      return PartGroupCategory.Event;
    case RenderableType.Chat:
      return PartGroupCategory.OutboundChat;
    case RenderableType.PriorityChangedByWorkflow:
      return PartGroupCategory.Event;
    case RenderableType.PriorityChanged:
      return PartGroupCategory.Event;
    case RenderableType.CustomBot:
      return PartGroupCategory.BotContent;
    case RenderableType.Email:
      return PartGroupCategory.OutboundEmail;
    case RenderableType.ConversationSlaTargetMissed:
      return PartGroupCategory.Event;
    case RenderableType.ChannelAndReplyTimeExpectation:
      return PartGroupCategory.BotContent;
    case RenderableType.DuplicateConversationNote:
      return PartGroupCategory.DuplicateConversationNote;
    case RenderableType.ParticipantAdded:
      return PartGroupCategory.Event;
    case RenderableType.ParticipantRemoved:
      return PartGroupCategory.Event;
    case RenderableType.ParticipantBlocked:
      return PartGroupCategory.Event;
    case RenderableType.UnengagedInboundBotIntroduction:
      return PartGroupCategory.Event;
    case RenderableType.ConversationRatingChanged:
      return PartGroupCategory.UserRating;
    case RenderableType.ConversationRatingRemarkAdded:
      return PartGroupCategory.UserRating;
    case RenderableType.PriorityChangedByRule:
      return PartGroupCategory.Event;
    case RenderableType.UnknownParticipantForEmailConversationNote:
      return PartGroupCategory.UnknownParticipantForEmailConversationNote;
    case RenderableType.Article:
      return PartGroupCategory.Article;
    case RenderableType.AttachmentsDropped:
      return PartGroupCategory.Event;
    case RenderableType.Post:
      return PartGroupCategory.OutboundPost;
    case RenderableType.UserBecameIdle:
      return PartGroupCategory.Event;
    case RenderableType.AiAgentFoundNoAnswer:
      return PartGroupCategory.Event;
    case RenderableType.ChannelSwitched:
      return PartGroupCategory.Event;
    case RenderableType.Sms:
      return PartGroupCategory.OutboundSms;
    case RenderableType.Whatsapp:
      return PartGroupCategory.OutboundWhatsapp;
    case RenderableType.TicketStateUpdatedByAdmin: {
      if (part.renderableData?.adminOnly || part.isNewTicketEventPart) {
        return PartGroupCategory.TicketEvent;
      }
      return PartGroupCategory.TeammateContent;
    }
    case RenderableType.AvailableFinActions: {
      return PartGroupCategory.Event;
    }
    case RenderableType.CustomActionStarted: {
      return PartGroupCategory.Event;
    }
    case RenderableType.CustomActionFinished: {
      return PartGroupCategory.Event;
    }
    case RenderableType.TicketStateUpdated: {
      if (part.renderableData?.adminOnly) {
        return PartGroupCategory.TicketEvent;
      }
      return PartGroupCategory.TeammateContent;
    }
    case RenderableType.PhoneCall:
      return PartGroupCategory.Event;
    case RenderableType.ConversationSummary:
      return PartGroupCategory.BotContent;
    case RenderableType.RatingRequestNotSent:
      return PartGroupCategory.Event;
    case RenderableType.TicketShared:
      return PartGroupCategory.TicketEvent;
    case RenderableType.LinkedTicketShared:
      return PartGroupCategory.TicketEvent;
    case RenderableType.OperatorWorkflowEvent:
      return PartGroupCategory.Event;
    case RenderableType.CallSummary:
      return PartGroupCategory.CallSummary;
    case RenderableType.EntityLinked:
      return PartGroupCategory.TicketEvent;
    case RenderableType.Forwarded:
      return PartGroupCategory.Event;
    case RenderableType.CreatedByForwarding:
      return PartGroupCategory.Event;
    case RenderableType.FinAnswer:
      if (
        part.renderableData instanceof FinAnswer &&
        part.renderableData.channel === Channel.Email
      ) {
        return PartGroupCategory.BotEmailReply;
      }
      return PartGroupCategory.BotContent;
    case RenderableType.EntitiesUnlinked:
      return PartGroupCategory.TicketEvent;
    case RenderableType.SideConversationStarted:
      return PartGroupCategory.SideConversationEvent;
    case RenderableType.AutoSummarizeLimitReached:
      return PartGroupCategory.Event;
    case RenderableType.SideConversationReply:
      return PartGroupCategory.SideConversationEvent;
    case RenderableType.MergedConversationReply:
      return PartGroupCategory.MergedConversationEvent;
    case RenderableType.MergedUserComment:
      return PartGroupCategory.MergedUserContent;
    case RenderableType.TransferCall:
      return PartGroupCategory.Event;
    case RenderableType.MergedPrimaryConversation:
      return PartGroupCategory.MergedPrimaryConversationEvent;
    case RenderableType.MergedSecondaryConversation:
      return PartGroupCategory.MergedSecondaryConversationEvent;
    case RenderableType.LanguageDetectionDetails:
      return PartGroupCategory.Event;
    case RenderableType.FinGuidanceApplied:
      return PartGroupCategory.Event;
    case RenderableType.CallEvent:
      return PartGroupCategory.Event;
    case RenderableType.ConversationTagsUpdated:
      return PartGroupCategory.Event;
    case RenderableType.GenericHandoff:
      return PartGroupCategory.Event;
    case RenderableType.TranscriptionSummary:
      return PartGroupCategory.BotContent;
    case RenderableType.FinCustomisationSettingsApplied:
      return PartGroupCategory.Event;
    case RenderableType.CustomAnswerApplied:
      return PartGroupCategory.Event;
    default:
      return PartGroupCategory.Unknown;
  }
};

export function isPartCategory(part: RenderablePart, category: PartGroupCategory) {
  return partCategoryMapping(part) === category;
}

export default class PartGroup {
  conversation: Conversation;
  index: number;
  allParts: Array<RenderablePart>;
  category: PartGroupCategory;
  hiddenCategories: Array<PartGroupCategory> = [];

  constructor(
    conversation: Conversation,
    index: number,
    parts: Array<RenderablePart>,
    category: PartGroupCategory,
    hiddenCategories: Array<PartGroupCategory>,
  ) {
    this.conversation = conversation;
    this.index = index;
    this.allParts = parts;
    this.category = category;
    this.hiddenCategories = hiddenCategories;
  }

  get identifier() {
    return `${this.conversation.id}-${this.allParts[0].uuid}-${this.category}`;
  }

  get parts() {
    return this.allParts.reject((part) =>
      this.hiddenCategories.includes(partCategoryMapping(part)),
    );
  }

  get isEmpty() {
    return this.parts.length === 0;
  }

  get isUser(): boolean {
    // groupParts groups by creating entity so we can just check 1 part
    return !this.isEmpty && this.parts[0].createdByUser;
  }

  static groupParts(
    conversation: Conversation,
    parts: Array<RenderablePart>,
    forcedSplits: Array<string> = [],
    hiddenCategories: Array<PartGroupCategory> = [],
  ): Array<PartGroup> {
    let groups: Array<PartGroup> = [];
    parts.forEach((part, index) => {
      let category = partCategoryMapping(part);
      let previousPart: RenderablePart | undefined;
      if (index > 0) {
        previousPart = parts[index - 1];
      }

      let lastGroup: PartGroup | undefined;
      if (groups.length) {
        lastGroup = groups[groups.length - 1];
      }
      if (
        part.isGroupable &&
        lastGroup &&
        previousPart &&
        category === partCategoryMapping(previousPart) &&
        ((part.createdBySameEntity(previousPart) && part.createdWithin1Minute(previousPart)) ||
          // We just need to bucket events by user/non-user to align on correct side of thread
          (category === PartGroupCategory.Event &&
            !isUserPartAndNonUserPart(part, previousPart))) &&
        !forcedSplits.includes(part.uuid)
      ) {
        lastGroup.allParts.push(part);
      } else {
        let group = new PartGroup(conversation, index, [part], category, hiddenCategories);
        groups.push(group);
      }
    });

    return groups;
  }
}

function isUserPartAndNonUserPart(part1: RenderablePart, part2: RenderablePart): boolean {
  let partTypes = new Set([
    part1.renderableData.creatingEntity.type,
    part2.renderableData.creatingEntity.type,
  ]);
  return partTypes.size > 1 && partTypes.has(EntityType.User);
}
