/* RESPONSIBLE TEAM: team-actions */

// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { inject as service } from '@ember/service';
import type MutableArray from '@ember/array/mutable';

import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import type { PathConfigGenerationParams } from 'embercom/objects/visual-builder/configuration/editor';
import { AiAgentWorkflowPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import type IntlService from 'embercom/services/intl';

export default class AiAgentWorkflowEditorConfig extends EditorConfig {
  @service declare intl: IntlService;

  public targetChannels: MutableArray<string>;

  constructor({
    areStepPaywallsActive,
    workflow,
    targetChannels,
  }: {
    areStepPaywallsActive?: boolean;
    workflow?: Workflow;
    targetChannels: MutableArray<string>;
  }) {
    super({ areStepPaywallsActive, workflow });
    this.targetChannels = targetChannels;
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new AiAgentWorkflowPathConfig({
      path,
      editorState,
      supportsLocalAttributes: this.supportsLocalAttributes,
    });
  }

  get showGoalsPanel(): boolean {
    return false;
  }
}
