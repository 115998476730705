/* RESPONSIBLE TEAM: team-workflows */
import EditorConfig, {
  type PathConfigGenerationParams,
  type StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { InboundPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import { InboundBotReplyButtonsConfiguration } from 'embercom/objects/visual-builder/configuration/step/reply-buttons';
import type Step from 'embercom/models/operator/visual-builder/step';

export default class InboundBotEditorConfig extends EditorConfig {
  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new InboundPathConfig({
      path,
      editorState,
      supportsLocalAttributes: this.supportsLocalAttributes,
    });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.replyButtons:
        return new InboundBotReplyButtonsConfiguration(stepConfigArgs);
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }
}
