/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import RenderablePart, { type RenderablePartWireFormat } from './renderable-part';
import {
  TicketCategory,
  TicketType,
  type TicketTypeWireFormat,
} from 'embercom/objects/inbox/ticket';
import { type ParticipantUserSummaryWireFormat } from './participant-user-summary';
import type ParticipantUserSummary from './participant-user-summary';
import { firstConversationParticipant } from './participant-user-summary';
import type LinkedTicketSummary from 'embercom/objects/inbox/linked-ticket-summary';
import AdminSummary, { type AdminSummaryWireFormat } from './admin-summary';
import { ConversationState, type TicketSystemState } from './conversation';
import TeamSummary, { type TeamSummaryWireFormat } from './team-summary';
import type Session from 'embercom/services/session';
import { setOwner } from '@ember/application';
import ConversationAttributeSummary, {
  type ConversationAttributeSummaryWireFormat,
} from './conversation-attribute-summary';
// disabling rule and importing getContainer as it's being used temporarly only here
// eslint-disable-next-line no-restricted-imports
import { getContainer } from 'embercom/lib/container-lookup';
import { DEFAULT_TICKET_TYPE_ATTRIBUTES } from 'embercom/lib/inbox/constants';
import ConversationSlaState, {
  type ConversationSlaStateWireFormat,
} from 'embercom/objects/inbox/conversation-sla-state';
import moment from 'moment-timezone';
import { type RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { ChannelData, type ChannelDataWireFormat } from 'embercom/models/data/inbox/channels';

export interface ConversationSummaryConstructorFormat {
  id: number;
  redacted?: boolean;
  title?: string;
  user: UserSummary;
  priority: boolean;
  state: ConversationState;
  ticketState?: TicketSystemState;
  ticketCustomStateId?: number;
  adminAssignee?: AdminSummary;
  teamAssignee?: TeamSummary;
  lastUpdated: Date;
  lastRenderableSummaryPart?: RenderablePart;
  lastRenderablePart?: RenderablePart;
  lastAdminMentionedPart?: RenderablePart;
  nextBreachTime?: Date;
  conversationSlaStates?: ConversationSlaState[];
  lastSeenByAdminAt?: Date;
  latestAdminVisibleCommentAt?: Date;
  ticketType?: TicketType;
  participantSummaries?: ParticipantUserSummary[];
  waitingSince?: Date;
  visibleToUser?: boolean;
  canReplyToUser: boolean;
  parent?: LinkedTicketSummary;
  attributes?: ConversationAttributeSummary[];
  isInboundConversation?: boolean;
  linkedConversationIds: number[] | null;
  linkedCustomerTicketIds: number[] | null;
  createdAt?: Date;
  ticketId?: string;
  ticketCategory?: TicketCategory;
  helpCenterId?: string;
  lastAssignedAt?: Date;
  lastPartCreatedAt?: Date;
  lastPartCreatedType?: string;
  companyId?: string;
  assignmentTracingData?: {
    lastAssignedAt: Date | undefined;
    lastPartCreatedAt: Date | undefined;
    lastPartCreatedType: string | undefined;
    lastLbaInitialEnqueueAt: Date | undefined;
    lastLbaCalculateEnqueuedAt: Date | undefined;
    lastLbaAssignEnqueuedAt: Date | undefined;
    lastLbaAssignedAt: Date | undefined;
    lastLbaTeamAssignedAt: Date | undefined;
  };
  channel?: ChannelData;
}

export interface ConversationSummaryWireFormat {
  id: number;
  redacted?: boolean;
  title?: string;
  user_summary: UserSummaryWireFormat;
  priority: boolean;
  last_updated: string;
  last_renderable_part?: RenderablePartWireFormat;
  last_admin_mentioned_part?: RenderablePartWireFormat | null;
  next_breach_time?: string;
  conversation_sla_states?: ConversationSlaStateWireFormat[];
  last_seen_by_admin_at?: string;
  latest_admin_visible_comment_at?: string;
  ticket_type?: TicketTypeWireFormat;
  participant_summaries?: ParticipantUserSummaryWireFormat[];
  waiting_since?: string;
  visible_to_user?: boolean;
  can_reply_to_user: boolean;
  parent?: LinkedTicketSummary;
  state: ConversationState;
  ticket_state?: TicketSystemState;
  ticket_custom_state_id?: number;
  admin_assignee?: AdminSummaryWireFormat;
  team_assignee?: TeamSummaryWireFormat;
  attributes?: ConversationAttributeSummaryWireFormat[];
  is_inbound_conversation?: boolean;
  relevant_part?: RenderablePartWireFormat;
  linked_conversation_ids: number[] | null;
  linked_customer_ticket_ids: number[] | null;
  created_at?: string;
  ticket_id?: string;
  ticket_category?: TicketCategory;
  help_center_id?: string;
  last_assigned_at?: number;
  last_part_created_at?: number;
  last_part_created_type?: string;
  company_id?: string;
  assignment_tracing_data?: {
    last_assigned_at: number;
    last_part_created_at: number;
    last_part_created_type: string;
    last_lba_initial_enqueue_at: number;
    last_lba_calculate_enqueued_at: number;
    last_lba_assign_enqueued_at: number;
    last_lba_assigned_at: number;
    last_lba_team_assigned_at: number;
  };
  channel?: ChannelDataWireFormat;
}

export default class ConversationSummary {
  readonly isTicket: boolean = false;

  readonly id: number;
  readonly redacted: boolean;
  readonly user: UserSummary;
  readonly lastSeenByAdminAt?: Date;
  readonly latestAdminVisibleCommentAt?: Date;
  readonly participantSummaries: ParticipantUserSummary[] = [];
  readonly waitingSince?: Date;
  readonly ticketId?: string;
  readonly linkedConversationIds: number[] | null;
  readonly linkedCustomerTicketIds: number[] | null;
  readonly parent?: LinkedTicketSummary;
  readonly isInboundConversation?: boolean;
  readonly ticketCategory?: TicketCategory;
  readonly helpCenterId?: string;
  readonly lastAssignedAt?: Date;
  readonly lastPartCreatedAt?: Date;
  readonly lastPartCreatedType?: string;
  readonly assignmentTracingData?: any;

  @service declare session: Session;

  @tracked state: ConversationState;
  @tracked ticketState?: TicketSystemState;
  @tracked ticketCustomStateId?: number;
  @tracked adminAssignee?: AdminSummary;
  @tracked teamAssignee?: TeamSummary;
  @tracked priority: boolean;
  @tracked ticketType?: TicketType;
  @tracked visibleToUser?: boolean;
  @tracked canReplyToUser: boolean;
  @tracked title?: string;
  @tracked lastRenderableSummaryPart?: RenderablePart;
  @tracked lastRenderablePart?: RenderablePart;
  @tracked lastAdminMentionedPart?: RenderablePart;
  @tracked lastUpdated: Date;
  @tracked nextBreachTime?: Date;
  @tracked conversationSlaStates?: ConversationSlaState[];
  @tracked attributes?: ConversationAttributeSummary[];
  @tracked createdAt?: Date;
  @tracked companyId?: string;
  @tracked isRead = false;
  @tracked channel?: ChannelData;

  constructor(inputs: ConversationSummaryConstructorFormat) {
    let {
      id,
      redacted,
      title,
      user,
      priority,
      lastUpdated,
      lastRenderableSummaryPart,
      lastRenderablePart,
      lastAdminMentionedPart,
      nextBreachTime,
      conversationSlaStates,
      lastSeenByAdminAt,
      latestAdminVisibleCommentAt,
      ticketType,
      participantSummaries,
      waitingSince,
      visibleToUser,
      parent,
      state,
      ticketState,
      ticketCustomStateId,
      adminAssignee,
      teamAssignee,
      attributes,
      isInboundConversation,
      linkedConversationIds,
      linkedCustomerTicketIds,
      createdAt,
      ticketId,
      canReplyToUser,
      ticketCategory,
      helpCenterId,
      lastAssignedAt,
      lastPartCreatedAt,
      lastPartCreatedType,
      companyId,
      assignmentTracingData,
      channel,
    } = inputs;
    setOwner(this, getContainer());
    this.id = id;
    this.redacted = !!redacted;
    this.title = title;
    this.user = user;
    this.priority = priority;
    this.lastUpdated = lastUpdated;
    this.lastRenderableSummaryPart = lastRenderableSummaryPart;
    this.lastRenderablePart = lastRenderablePart;
    this.lastAdminMentionedPart = lastAdminMentionedPart;
    this.nextBreachTime = nextBreachTime;
    this.conversationSlaStates = conversationSlaStates;
    this.lastSeenByAdminAt = lastSeenByAdminAt;
    this.latestAdminVisibleCommentAt = latestAdminVisibleCommentAt;
    this.ticketType = ticketType;
    this.isTicket = !!ticketType;
    this.participantSummaries = participantSummaries ?? [];
    this.waitingSince = waitingSince;
    this.visibleToUser = visibleToUser;
    this.parent = parent;
    this.state = state;
    this.ticketState = ticketState;
    this.ticketCustomStateId = ticketCustomStateId;
    this.adminAssignee = adminAssignee;
    this.teamAssignee = teamAssignee;
    this.attributes = attributes;
    this.isInboundConversation = isInboundConversation;
    this.linkedConversationIds = linkedConversationIds;
    this.linkedCustomerTicketIds = linkedCustomerTicketIds;
    this.createdAt = createdAt || undefined;
    this.ticketId = ticketId;
    this.canReplyToUser = canReplyToUser;
    this.ticketCategory = ticketCategory;
    this.helpCenterId = helpCenterId;
    this.lastAssignedAt = lastAssignedAt;
    this.lastPartCreatedAt = lastPartCreatedAt;
    this.lastPartCreatedType = lastPartCreatedType;
    this.companyId = companyId;
    this.assignmentTracingData = assignmentTracingData;
    this.isRead = this.redacted;
    if (this.lastSeenByAdminAt && this.latestAdminVisibleCommentAt) {
      if (this.lastRenderableSummaryPart) {
        this.isRead = this.lastSeenByAdminAt >= this.lastRenderableSummaryPart.createdAt;
      } else {
        this.isRead = this.lastSeenByAdminAt >= this.latestAdminVisibleCommentAt;
      }
    }
    this.channel = channel;
  }

  get isClosed() {
    return this.state === ConversationState.Closed;
  }

  get isOpen() {
    return this.state === ConversationState.Open;
  }

  get isSnoozed() {
    return this.state === ConversationState.Snoozed;
  }

  static deserialize(json: ConversationSummaryWireFormat) {
    let lastRenderableSummaryPart = undefined;
    if (json.last_renderable_part) {
      lastRenderableSummaryPart = RenderablePart.deserialize(json.last_renderable_part);
    }
    let lastAdminMentionedPart = undefined;
    if (json.last_admin_mentioned_part) {
      lastAdminMentionedPart = RenderablePart.deserialize(json.last_admin_mentioned_part);
    }

    let nextBreachTime = json.next_breach_time ? new Date(json.next_breach_time) : undefined;
    let conversationSlaStates = json.conversation_sla_states
      ? json.conversation_sla_states.map(ConversationSlaState.deserialize)
      : undefined;
    let lastSeenByAdminAt = json.last_seen_by_admin_at
      ? new Date(json.last_seen_by_admin_at)
      : undefined;
    let latestAdminVisibleCommentAt = json.latest_admin_visible_comment_at
      ? new Date(json.latest_admin_visible_comment_at)
      : undefined;

    let visibleToUser = json.visible_to_user;

    let ticketType = json.ticket_type ? TicketType.deserialize(json.ticket_type) : undefined;

    let waitingSince = json.waiting_since ? new Date(json.waiting_since) : undefined;

    let parent = json.parent;

    let attributes = json.attributes
      ? json.attributes.map((attribute) => ConversationAttributeSummary.deserialize(attribute))
      : undefined;

    let createdAt = json.created_at ? new Date(json.created_at) : undefined;
    let lastAssignedAt = json.last_assigned_at ? new Date(json.last_assigned_at) : undefined;

    let assignmentTracingData = {
      lastAssignedAt: json?.assignment_tracing_data?.last_assigned_at
        ? new Date(json?.assignment_tracing_data?.last_assigned_at)
        : undefined,
      lastPartCreatedAt: json?.assignment_tracing_data?.last_part_created_at
        ? new Date(json?.assignment_tracing_data?.last_part_created_at)
        : undefined,
      lastPartCreatedType: json?.assignment_tracing_data?.last_part_created_type,
      lastLbaInitialEnqueueAt: json?.assignment_tracing_data?.last_lba_initial_enqueue_at
        ? new Date(json?.assignment_tracing_data?.last_lba_initial_enqueue_at)
        : undefined,
      lastLbaCalculateEnqueuedAt: json?.assignment_tracing_data?.last_lba_calculate_enqueued_at
        ? new Date(json?.assignment_tracing_data?.last_lba_calculate_enqueued_at)
        : undefined,
      lastLbaAssignEnqueuedAt: json?.assignment_tracing_data?.last_lba_assign_enqueued_at
        ? new Date(json?.assignment_tracing_data?.last_lba_assign_enqueued_at)
        : undefined,
      lastLbaAssignedAt: json?.assignment_tracing_data?.last_lba_assigned_at
        ? new Date(json?.assignment_tracing_data?.last_lba_assigned_at)
        : undefined,
      lastLbaTeamAssignedAt: json?.assignment_tracing_data?.last_lba_team_assigned_at
        ? new Date(json?.assignment_tracing_data?.last_lba_team_assigned_at)
        : undefined,
    };

    let lastPartCreatedAt = json.last_part_created_at
      ? new Date(json.last_part_created_at)
      : undefined;
    let lastPartCreatedType = json.last_part_created_type;
    let companyId = json.company_id;

    let channel = json.channel ? ChannelData.deserialize(json.channel) : undefined;

    return new ConversationSummary({
      id: json.id,
      redacted: json.redacted,
      title: json.title,
      user: UserSummary.deserialize(json.user_summary),
      priority: json.priority,
      lastUpdated: new Date(json.last_updated),
      lastRenderableSummaryPart,
      lastAdminMentionedPart,
      nextBreachTime,
      conversationSlaStates,
      lastSeenByAdminAt,
      latestAdminVisibleCommentAt,
      ticketType,
      participantSummaries: json.participant_summaries?.compact().map(UserSummary.deserialize),
      waitingSince,
      visibleToUser,
      canReplyToUser: json.can_reply_to_user,
      parent,
      state: json.state,
      ticketState: json.ticket_state,
      ticketCustomStateId: json.ticket_custom_state_id,
      adminAssignee: json.admin_assignee
        ? AdminSummary.deserialize(json.admin_assignee)
        : undefined,
      teamAssignee: json.team_assignee ? TeamSummary.deserialize(json.team_assignee) : undefined,
      attributes,
      isInboundConversation: json.is_inbound_conversation,
      linkedConversationIds: json.linked_conversation_ids,
      linkedCustomerTicketIds: json.linked_customer_ticket_ids,
      createdAt,
      ticketId: json.ticket_id,
      ticketCategory: json.ticket_category,
      helpCenterId: json.help_center_id,
      lastAssignedAt,
      lastPartCreatedAt,
      lastPartCreatedType,
      companyId,
      assignmentTracingData,
      channel,
    });
  }

  get hasMultipleParticipants() {
    return this.participantSummaries.length > 1;
  }

  get firstParticipant(): UserSummary {
    return firstConversationParticipant(this.participantSummaries) ?? this.user;
  }

  get isReplyable(): boolean {
    return this.canReplyToUser;
  }

  get linkedCustomerReportIds() {
    return (this.linkedConversationIds || []).concat(this.linkedCustomerTicketIds || []);
  }

  get isChild() {
    return this.parent;
  }

  markAsRead(): void {
    this.isRead = true;
  }

  markAsUnread(): void {
    this.isRead = false;
  }

  get isNotAssigned() {
    return (
      (!this.adminAssignee || this.adminAssignee.isUnassignedAssignee) &&
      (!this.teamAssignee || this.teamAssignee.isUnassignedAssignee)
    );
  }

  get ticketTitle() {
    if (!this.isTicket) {
      return '';
    }

    let titleAttribute = this.attributes?.find(
      (attr) => attr.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.TITLE,
    );

    if (titleAttribute) {
      return titleAttribute.value;
    }
    return this.ticketType?.name;
  }

  get ticketDescription() {
    if (!this.isTicket) {
      return '';
    }

    let descriptionAttribute = this.attributes?.find(
      (attr) => attr.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.DESCRIPTION,
    );

    if (descriptionAttribute) {
      return descriptionAttribute.value;
    }

    return '';
  }

  get ticketDescriptionAsBlocks() {
    let description = this.ticketDescription;

    if (description === '') {
      return [];
    }

    if (typeof description === 'string') {
      try {
        return JSON.parse(description);
      } catch {
        return [];
      }
    }

    return [];
  }

  get isTrackerTicket(): boolean {
    return this.ticketCategory === TicketCategory.Tracker;
  }

  get isCustomerTicket(): boolean {
    return this.ticketCategory === TicketCategory.Request;
  }

  get isBackOfficeTicket(): boolean {
    return this.ticketCategory === TicketCategory.Task;
  }

  isAssignedTo(entityId: number): boolean {
    return this.adminAssignee?.id === entityId || this.teamAssignee?.id === entityId;
  }

  hasAttributeForDescriptor(id: number | string) {
    return this.attributes?.any((attribute) => attribute.descriptor.id === id);
  }

  addAttribute(attribute: ConversationAttributeSummary) {
    if (!this.attributes) {
      return;
    }
    if (this.hasAttributeForDescriptor(attribute.descriptor.id)) {
      return;
    }

    this.attributes = [...this.attributes, attribute];
  }

  isLastPart(type: RenderableType) {
    return this.lastRenderableSummaryPart?.renderableType === type;
  }

  isLastPartCreatedWithin({ seconds }: { seconds: number }) {
    if (!this.lastRenderableSummaryPart) {
      return false;
    }

    return moment().diff(moment(this.lastRenderableSummaryPart.createdAt), 'second') <= seconds;
  }
}
