/* RESPONSIBLE TEAM: team-standalone */
import EditorConfig, {
  type PathConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { NewApiConversationPathConfig } from 'embercom/objects/visual-builder/configuration/path';

export default class NewApiConversationBotEditorConfig extends EditorConfig {
  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new NewApiConversationPathConfig({
      path,
      editorState,
      supportsLocalAttributes: this.supportsLocalAttributes,
    });
  }

  get supportsConversationChannelTargeting(): boolean {
    return false;
  }

  get supportsSideSheet(): boolean {
    return false;
  }
}
