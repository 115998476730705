/* RESPONSIBLE TEAM: team-ai-agent */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';

interface Guideline {
  id: string;
  text: string;
  title?: string;
}

interface FinGuidanceAppliedWireFormat {
  renderable_type: RenderableType;
  guidelines: Guideline[];
}

export default class FinGuidanceApplied {
  readonly renderableType: RenderableType = RenderableType.FinGuidanceApplied;
  readonly guidelines: Guideline[];

  constructor(guidelines: Guideline[]) {
    this.guidelines = guidelines;
  }

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  static deserialize(json: FinGuidanceAppliedWireFormat): FinGuidanceApplied {
    return new FinGuidanceApplied(json.guidelines);
  }
}
