/* RESPONSIBLE TEAM: team-standalone */

import { tracked } from '@glimmer/tracking';
import {
  REQUIRED_ATTRIBUTES,
  type TicketField,
  UserField,
} from 'embercom/objects/standalone/data-fields/common';

export class SalesforceDataFields {
  @tracked userFields: UserField[] = [];
  @tracked caseFields: TicketField[] = [];

  constructor(inputs: { userFields: UserField[]; caseFields: TicketField[] }) {
    this.userFields = this.requiredUserFields.concat(inputs.userFields);
    this.caseFields = this.requiredCaseFields.concat(inputs.caseFields);
  }

  private get requiredUserFields(): UserField[] {
    return REQUIRED_ATTRIBUTES.userFields.map((field) => {
      return new UserField({
        id: field.platformIdentifier,
        name: this.titleCase(field.platformIdentifier),
        required: true,
        type: 'string',
        intercom_sda: {
          identifier: field.intercomAttribute,
          type: field.intercomFieldType,
        },
      });
    });
  }

  get requiredCaseFields(): TicketField[] {
    return REQUIRED_ATTRIBUTES.caseFields.map((field) => {
      return {
        id: field,
        name: this.titleCase(field),
        required: true,
        type: 'string',
      };
    });
  }

  titleCase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
