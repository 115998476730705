/* RESPONSIBLE TEAM: team-standalone */
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

// In the backend, these are defined in app/lib/standalone/constants/integration_states.rb
export enum IntegrationState {
  NotConfigured = -1,
  Created = 0,
  Paused = 1,
  Enabled = 2,
  TestMode = 3,
}

// In the backend, these are defined in app/lib/standalone/constants/handoff_behaviors.rb
export enum HandoffBehavior {
  NotConfigured = 0,
  ZendeskLiveAgent = 1,
  ZendeskTicket = 2,
  ZendeskHybrid = 3,
  GenericJs = 4,
  GenericUrl = 5,
  GenericCustomAction = 6,
}

export type StandalonePlatform =
  | 'zendesk'
  | 'salesforce'
  | 'hubspot'
  | 'freshdesk'
  | 'front'
  | 'amazon_connect'
  | 'help_scout'
  | 'servicenow'
  | 'gorgias'
  | 'zoho_desk';

export const RESPONSE_PROVIDED_ERRORS = [400, 401, 403, 404, 409, 422, 424];
export const StandalonePlatformToIcon: Record<StandalonePlatform, InterfaceIconName | undefined> = {
  zendesk: 'zendesk',
  salesforce: 'salesforce',
  hubspot: 'hubspot',
  freshdesk: undefined,
  front: undefined,
  amazon_connect: undefined,
  help_scout: undefined,
  servicenow: undefined,
  gorgias: undefined,
  zoho_desk: undefined,
};
