/* RESPONSIBLE TEAM: team-workflows */
import EditorConfig, {
  type PathConfigGenerationParams,
  type StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import { CustomAnswerPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import { CustomAnswerReplyButtonsConfiguration } from 'embercom/objects/visual-builder/configuration/step/reply-buttons';
import type Step from 'embercom/models/operator/visual-builder/step';
import type MutableArray from '@ember/array/mutable';
import { tracked } from '@glimmer/tracking';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';

export default class CustomAnswerEditorConfig extends EditorConfig {
  @tracked targetChannels: MutableArray<string>;

  constructor({
    targetChannels,
    workflow,
  }: {
    targetChannels: MutableArray<string>;
    workflow?: Workflow;
  }) {
    super({ areStepPaywallsActive: false, workflow });
    this.targetChannels = targetChannels;
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new CustomAnswerPathConfig({
      path,
      editorState,
      supportsLocalAttributes: this.supportsLocalAttributes,
    });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.replyButtons:
        return new CustomAnswerReplyButtonsConfiguration(stepConfigArgs);
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }

  get supportsLocalAttributes(): boolean {
    return false;
  }
}
