/* RESPONSIBLE TEAM: team-product-exploration */
/* eslint-disable @intercom/intercom/no-bare-strings */

// This file powers search within the product.
// The data here is used to generate the list of all routes that are searchable within global search,
// along with search synonyms, and their children.
//
// If you have added a new route to the site you should add it to the correct part of this data structure to ensure it
// appears in search. Routes are grouped by how they appear in the nav in the UI.
// Example:
//   {
//     route: 'apps.app.settings.my-cool-new-feature-settings',
//     name: 'Cool New Feature',
//     synonyms: ['Excellent new Feature', 'Amazing new feature'],
//     requiredFeatureFlags: ['my-cool-new-feature'], // Optional - if the route requires a feature flag to be accessed
//     children: [
//       ...
//     ],
//   },
//
// NOTE: If you don't want your route to appear in search, you can add it to the exclusion list at the bottom of this file.

import type SearchableRoute from './searchable-route';
const GlobalRoutes: Array<SearchableRoute> = [
  {
    route: 'apps.app.automation.fin-ai-agent.setup',
    name: 'AI & Automation',
    synonyms: ['AI', 'Automation'],
    children: [
      {
        route: 'apps.app.automation.fin-ai-agent.setup',
        name: 'Fin AI Agent',
        synonyms: ['Fin', 'Agent'],
        children: [
          {
            route: 'apps.app.automation.fin-ai-agent.setup',
            name: 'Overview',
            synonyms: ['Overview'],
          },
          {
            route: 'apps.app.automation.fin-ai-agent.guidance',
            name: 'Guidance',
            synonyms: ['Guidance', 'Guidelines'],
            requiredFeatureFlags: ['fin-guidance-closed-beta'],
          },
          {
            route: 'apps.app.automation.fin-ai-agent.content-suggestions',
            name: 'Content Suggestions',
            synonyms: ['Content', 'Suggestions'],
          },
          {
            route: 'apps.app.automation.fin-ai-agent.custom-answers',
            name: 'Custom Answers',
            synonyms: ['Answers'],
          },
          {
            route: 'apps.app.automation.fin-ai-agent.profiles',
            name: 'Profiles',
            synonyms: ['Profiles'],
          },
          {
            route: 'apps.app.automation.fin-ai-agent.ai-categories',
            name: 'AI Categories',
            synonyms: ['AI Categories'],
          },
        ],
      },
      {
        route: 'apps.app.automation.workflows-overview',
        name: 'Workflows',
        synonyms: ['Workflows'],
        children: [
          {
            route: 'apps.app.automation.workflows-overview',
            name: 'Create',
            synonyms: ['Create'],
          },
          {
            route: 'apps.app.automation.workflows-overview',
            name: 'Edit',
            synonyms: ['Edit'],
          },
          {
            route: 'apps.app.automation.workflows-overview',
            name: 'View',
            synonyms: ['View'],
          },
        ],
      },
      {
        route: 'apps.app.automation.basics',
        name: 'Basics',
        synonyms: ['Basics'],
        children: [
          {
            route: 'apps.app.user.segments.all-users',
            name: 'For Users',
            synonyms: ['Users'],
          },
          {
            route: 'apps.app.user.segments.all-users',
            name: 'For Leads',
            synonyms: ['Leads'],
            routeParams: {
              for: 'leads',
            },
          },
        ],
      },
    ],
  },
  {
    route: 'apps.app.knowledge-hub.overview',
    name: 'Knowledge',
    synonyms: ['Knowledge', 'Content', 'Sources'],
    children: [
      {
        route: 'apps.app.knowledge-hub.overview',
        name: 'Sources',
        synonyms: ['Sources'],
        children: [
          {
            route: 'apps.app.knowledge-hub.overview',
            name: 'All',
            synonyms: ['Sources'],
          },
          {
            route: 'apps.app.knowledge-hub.overview',
            name: 'For AI Agent',
            synonyms: ['AI Agent'],
          },
          {
            route: 'apps.app.knowledge-hub.overview',
            name: 'For AI Copilot',
            synonyms: ['AI Copilot'],
          },
          {
            route: 'apps.app.knowledge-hub.overview',
            name: 'For Help Center',
            synonyms: ['Help Center'],
          },
        ],
      },
      {
        route: 'apps.app.knowledge-hub.all-content',
        name: 'All Content',
        synonyms: ['Cotnent'],
      },
    ],
  },
  {
    route: 'apps.app.reports',
    name: 'Reports',
    synonyms: ['Analytics', 'Metrics', 'Statistics'],
    children: [
      {
        route: 'apps.app.reports.overview',
        name: 'Overview',
        synonyms: ['Summary', 'Dashboard'],
      },
      {
        route: 'apps.app.reports.customer-support.export',
        name: 'Data Export',
        synonyms: ['Conversation Data', 'Ticket Data'],
      },
      {
        route: 'apps.app.reports.data-export',
        name: 'Data Export',
        requiredFeatureFlags: ['reporting-new-data-export'],
      },

      {
        route: 'apps.app.reports.customer-support',
        name: 'Human Support',
        synonyms: ['Customer Support'],
        children: [
          {
            route: 'apps.app.reports.customer-support.calls',
            name: 'Calls',
            synonyms: ['Phone Support', 'Call Analytics'],
          },
          {
            route: 'apps.app.reports.customer-support.conversations-flexible',
            name: 'Conversations Flexible',
            synonyms: ['Advanced Chat Analytics', 'Custom Conversation Metrics'],
          },
          {
            route: 'apps.app.reports.customer-support.effectiveness',
            name: 'Effectiveness',
            synonyms: ['Support Efficiency', 'Resolution Metrics'],
          },
          {
            route: 'apps.app.reports.customer-support.conversation-tags',
            name: 'Conversation Tags',
            synonyms: ['Chat Categories', 'Message Labeling'],
          },

          {
            route: 'apps.app.reports.customer-support.customer-satisfaction',
            name: 'Customer Satisfaction',
            synonyms: ['CSAT', 'Happiness Score'],
          },
          {
            route: 'apps.app.reports.customer-support.responsiveness',
            name: 'Responsiveness',
            synonyms: ['Response Time', 'Reply Speed'],
          },
          {
            route: 'apps.app.reports.customer-support.slas',
            name: 'SLAs',
            synonyms: ['Service Level Agreements', 'Performance Targets'],
          },
          {
            route: 'apps.app.reports.customer-support.team-inbox-performance-report',
            name: 'Team Inbox Performance Report',
            synonyms: ['Inbox Metrics', 'Shared Inbox Analytics'],
          },
          {
            route: 'apps.app.reports.customer-support.teammate-performance-report',
            name: 'Teammate Performance Report',
            synonyms: ['Individual Agent Metrics', 'Staff Performance'],
          },
        ],
      },
      {
        route: 'apps.app.reports.customer-support.conversational-support-funnel',
        name: 'Automation',
        synonyms: ['Support Journey', 'Conversation Flow'],
        children: [
          {
            route: 'apps.app.reports.customer-support.conversational-support-funnel',
            name: 'Conversational Support Funnel',
            synonyms: ['Support Journey', 'Conversation Flow'],
          },
          {
            route: 'apps.app.reports.customer-support.fin-ai-agent',
            name: 'Fin AI Agent',
            synonyms: ['AI Support Agent Analytics', 'Automated Agent Metrics'],
          },
          {
            route: 'apps.app.reports.customer-support.custom-bots',
            name: 'Workflow',
            synonyms: ['Bot Analytics', 'Automated Support Metrics'],
            children: [
              {
                route: 'apps.app.reports.customer-support.custom-bots.sent',
                name: 'Sent',
                synonyms: ['Bot Initiations', 'Triggered Bots'],
              },
              {
                route: 'apps.app.reports.customer-support.custom-bots.engaged',
                name: 'Engaged',
                synonyms: ['Bot Interactions', 'User Engagement'],
              },
              {
                route: 'apps.app.reports.customer-support.custom-bots.completed',
                name: 'Completed',
                synonyms: ['Finished Bot Sessions', 'Successful Bot Interactions'],
              },
            ],
          },
        ],
      },
      {
        route: 'apps.app.reports.customer-support.articles',
        name: 'Proactive Support',
        synonyms: ['Help Center Analytics', 'Knowledge Base Metrics'],
        children: [
          {
            route: 'apps.app.reports.customer-support.articles',
            name: 'Articles',
            synonyms: ['Help Center Analytics', 'Knowledge Base Metrics'],
          },
          {
            route: 'apps.app.reports.customer-support.articles.closed-by-operator',
            name: 'Closed by Operator',
            synonyms: ['Agent-Assisted Resolutions', 'Human Intervention'],
          },
        ],
      },
      {
        route: 'apps.app.reports.conversational-insights',
        name: 'Conversational Insights',
        synonyms: ['Chat Analysis', 'Conversation Trends'],
        children: [
          {
            route: 'apps.app.reports.conversational-insights.topics',
            name: 'Topics',
            synonyms: ['Conversation Themes', 'Discussion Categories'],
            children: [
              {
                route: 'apps.app.reports.conversational-insights.topics.topic',
                name: 'Topic Details',
                synonyms: ['Theme Analysis', 'Category Insights'],
              },
              {
                route: 'apps.app.reports.conversational-insights.topics.new',
                name: 'New Topic',
                synonyms: ['Create Topic', 'Add Theme'],
              },
            ],
          },
          {
            route: 'apps.app.reports.conversational-insights.suggested-topics',
            name: 'Suggested Topics',
            synonyms: ['AI-Generated Themes', 'Automated Categories'],
            children: [
              {
                route: 'apps.app.reports.conversational-insights.suggested-topics.topic',
                name: 'Suggested Topic Details',
                synonyms: ['AI Theme Analysis', 'Automated Category Insights'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    route: 'apps.app.inbox',
    name: 'Inbox',
    synonyms: ['Messages', 'Conversations', 'Helpdesk'],
    children: [
      {
        route: 'apps.app.inbox.new-conversation',
        name: 'New Conversation',
        synonyms: ['Create Message'],
      },
    ],
  },
  {
    route: 'apps.app.outbound.all',
    name: 'Outbound',
    synonyms: ['Outbound', 'Proactive'],
    children: [
      {
        route: 'apps.app.outbound.all',
        name: 'All Messages',
        synonyms: ['Outbound', 'Proactive'],
      },
      {
        route: 'apps.app.outbound.series',
        name: 'Series',
        synonyms: ['Series'],
      },
    ],
  },
  {
    route: 'apps.app.users.segments',
    name: 'Contacts',
    synonyms: ['Contacts', 'People', 'Leads', 'Users'],
    children: [
      {
        route: 'apps.app.users.segments.all-users',
        name: 'All Users',
        synonyms: ['People', 'Users', 'Segments'],
      },
      {
        route: 'apps.app.companies.segments',
        name: 'Companies',
        synonyms: ['Segments'],
      },
    ],
  },
];

export default GlobalRoutes;

// NOTE: Child routes will also be ignored
const IGNORED_NEW_SETTINGS_ROUTES: string[] = [];

export { IGNORED_NEW_SETTINGS_ROUTES };
