/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import {
  getApp,
  getAttributeService,
  getIntlService,
  getEmberDataStore,
} from 'embercom/lib/container-lookup';
import type Group from 'embercom/models/operator/visual-builder/group';
import { type CreateStepParams } from 'embercom/models/operator/visual-builder/step';
import INSERTER_MENU_ITEMS_MAP, {
  type InserterMenuItemType,
  inserterMenuItems,
  ADD_CONTENT_GROUP_NAME,
  ADD_CONVERSATION_ACTION_GROUP_NAME,
  ADD_INTEGRATION_ACTION_GROUP_NAME,
  CONNECT_EXISTING_PATH_GROUP_NAME,
  PROCEED_TO_ANOTHER_PATH_GROUP_NAME,
  SET_CONVERSATION_DATA_GROUP_NAME,
  SET_USER_DATA_GROUP_NAME,
  SUGGESTED_NEXT_STEPS_GROUP_NAME,
  type CustomHandlerFunction,
  type GroupNameType,
} from './inserter-menu-items-map';
import type Step from 'embercom/models/operator/visual-builder/step';
import type Edge from 'embercom/models/operator/visual-builder/edge';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type PathConfig from 'embercom/objects/visual-builder/configuration/path';
import type IntlService from 'embercom/services/intl';
import { cached } from 'tracked-toolbox';
import { suggestedNextSteps } from './suggested-menu-items-map';

export type StepGroupType = {
  groupId?: string;
  heading?: string;
  isOpenByDefault?: boolean;
  items: InserterMenuItemType[];
  showCountInHeading?: boolean;
  selectedChannels?: string[];
};

export type StepParameters = {
  ModelClass: typeof Step;
  stepCreationParams?: CreateStepParams;
  customItemHandler?: CustomHandlerFunction;
  analyticsData?: Record<string, unknown>;
};

export type EdgeParameters = { connectionPoint: ConnectionPoint };

export type InserterMenuConfigurationParams = {
  existingPaths?: Group[];
  excludedItems?: string[];
  allowedItems?: string[] | null;
  pathConfig: PathConfig;
  onSelectItem: (stepParams: StepParameters, edgeParams?: EdgeParameters) => void | Group | Edge;
};

export default class InserterMenuConfiguration {
  app: any;
  intl: IntlService;
  store: any;
  attributeService: any;
  onSelectItem: InserterMenuConfigurationParams['onSelectItem'];
  existingPaths: Group[];
  pathConfig: PathConfig;
  allAvailableStepGroups: StepGroupType[];

  /*
   * The Fin Standalone product introduces the concept of having workspace level constraints on what can be used in workflows.
   * Standalone supports a much smaller number of steps compared to the full Intercom product. To ensure that we don't show
   * new steps options added in the future by default, we ensure that only the specified steps that can be used.
   *
   * For regular Intercom workspaces, this list will include all the steps available in the inserter menu.
   *
   * We intend to move away from this and instead specify the steps allowed on each channel using the the editor config
   */
  @cached
  get appLevelAllowlist(): string[] {
    if (this.app.canUseStandalone) {
      return [
        inserterMenuItems.fin,
        inserterMenuItems.attributeCollector,
        inserterMenuItems.chatMessage,
        inserterMenuItems.conditionalBranches,
        inserterMenuItems.freeInput,
        inserterMenuItems.replyButtons,
        inserterMenuItems.setUserDataAttribute,
        inserterMenuItems.setConversationDataAttribute,
        inserterMenuItems.note,
        inserterMenuItems.summarizeConversation,
        inserterMenuItems.classifyConversationAttribute,
        inserterMenuItems.existingPaths,
        inserterMenuItems.handoffToZendeskTicket,
        inserterMenuItems.handoffToSalesforceCase,
        inserterMenuItems.handoffToSalesforceChat,
        inserterMenuItems.handoffToSalesforceInAppMessaging,
        inserterMenuItems.addTagToConversation,
        inserterMenuItems.handoffToUrl,
        inserterMenuItems.handoffWithJavaScript,
        inserterMenuItems.handoffToZendeskAgent,
        inserterMenuItems.handoffToSalesforceTeam,
        inserterMenuItems.finCustom,
        inserterMenuItems.workflowConnector,
        inserterMenuItems.wait,
        inserterMenuItems.waitForCallback,
      ];
    } else {
      return Object.values(inserterMenuItems);
    }
  }

  constructor({
    existingPaths = [],
    excludedItems = [],
    allowedItems = null,
    pathConfig,
    onSelectItem,
  }: InserterMenuConfigurationParams) {
    this.app = getApp();
    this.intl = getIntlService();
    this.store = getEmberDataStore();
    this.attributeService = getAttributeService();
    this.onSelectItem = onSelectItem;
    this.existingPaths = existingPaths;
    this.pathConfig = pathConfig;

    if (allowedItems === null) {
      allowedItems = this.appLevelAllowlist;
    }

    let getMenuItemsForTrigger = (itemNames: string[]): InserterMenuItemType[] => {
      let items: InserterMenuItemType[] = [];
      itemNames.forEach((name) => {
        if (!excludedItems.includes(name) && allowedItems!.includes(name)) {
          let item = INSERTER_MENU_ITEMS_MAP[name](this);
          if (item) {
            items.push(...item);
          }
        }
      });
      return items;
    };

    let getSuggestedNextSteps = (): InserterMenuItemType[] => {
      let trigger = this.pathConfig.editorState.workflow.workflowInstanceTrigger;

      if (trigger in suggestedNextSteps) {
        return getMenuItemsForTrigger(suggestedNextSteps[trigger]);
      }

      return getMenuItemsForTrigger(suggestedNextSteps.default);
    };

    let defaultGroups = {
      [SUGGESTED_NEXT_STEPS_GROUP_NAME]: {
        groupId: SUGGESTED_NEXT_STEPS_GROUP_NAME,
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${SUGGESTED_NEXT_STEPS_GROUP_NAME}`,
        ),
        items: getSuggestedNextSteps(),
        isOpenByDefault: true, // accordion is open
        selectedChannels:
          this.pathConfig.editorState.contentEditorService.activeObject?.targetChannels,
      },
      [CONNECT_EXISTING_PATH_GROUP_NAME]: {
        groupId: CONNECT_EXISTING_PATH_GROUP_NAME,
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${CONNECT_EXISTING_PATH_GROUP_NAME}`,
        ),
        showCountInHeading: true,
        items: [],
        selectedChannels:
          this.pathConfig.editorState.contentEditorService.activeObject?.targetChannels,
      },
      [ADD_CONTENT_GROUP_NAME]: {
        groupId: ADD_CONTENT_GROUP_NAME,
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${ADD_CONTENT_GROUP_NAME}`,
        ),
        isOpenByDefault: true, // accordion is open
        items: [],
        selectedChannels:
          this.pathConfig.editorState.contentEditorService.activeObject?.targetChannels,
      },
      [PROCEED_TO_ANOTHER_PATH_GROUP_NAME]: {
        groupId: PROCEED_TO_ANOTHER_PATH_GROUP_NAME,
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${PROCEED_TO_ANOTHER_PATH_GROUP_NAME}`,
        ),
        isOpenByDefault: true, // accordion is open
        items: [],
        selectedChannels:
          this.pathConfig.editorState.contentEditorService.activeObject?.targetChannels,
      },
      [ADD_CONVERSATION_ACTION_GROUP_NAME]: {
        groupId: ADD_CONVERSATION_ACTION_GROUP_NAME,
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${ADD_CONVERSATION_ACTION_GROUP_NAME}`,
        ),
        isOpenByDefault: true, // accordion is open
        items: [],
        selectedChannels:
          this.pathConfig.editorState.contentEditorService.activeObject?.targetChannels,
      },
      [SET_CONVERSATION_DATA_GROUP_NAME]: {
        groupId: SET_CONVERSATION_DATA_GROUP_NAME,
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${SET_CONVERSATION_DATA_GROUP_NAME}`,
        ),
        showCountInHeading: true,
        items: [],
        selectedChannels:
          this.pathConfig.editorState.contentEditorService.activeObject?.targetChannels,
      },
      [SET_USER_DATA_GROUP_NAME]: {
        groupId: SET_USER_DATA_GROUP_NAME,
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${SET_USER_DATA_GROUP_NAME}`,
        ),
        showCountInHeading: true,
        items: [],
        selectedChannels:
          this.pathConfig.editorState.contentEditorService.activeObject?.targetChannels,
      },
      [ADD_INTEGRATION_ACTION_GROUP_NAME]: {
        groupId: ADD_INTEGRATION_ACTION_GROUP_NAME,
        heading: this.intl.t(
          `operator.workflows.visual-builder.step-menu.${ADD_INTEGRATION_ACTION_GROUP_NAME}`,
        ),
        items: [],
        selectedChannels:
          this.pathConfig.editorState.contentEditorService.activeObject?.targetChannels,
      },
    } as Record<GroupNameType, StepGroupType>;

    let groupByGroupNameUnlessExcluded = (stepGroups: typeof defaultGroups, itemName: string) => {
      if (!excludedItems.includes(itemName) && allowedItems!.includes(itemName)) {
        let steps = INSERTER_MENU_ITEMS_MAP[itemName](this);
        if (steps) {
          steps.forEach((step) => step.groupName && stepGroups[step.groupName].items.push(step));
        }
      }

      return stepGroups;
    };

    this.allAvailableStepGroups = [
      ...Object.values(
        Object.keys(INSERTER_MENU_ITEMS_MAP).reduce(groupByGroupNameUnlessExcluded, defaultGroups),
      ),
    ].filter(
      (group) =>
        (group.groupId !== SUGGESTED_NEXT_STEPS_GROUP_NAME && group.items.length > 0) ||
        (group.groupId === SUGGESTED_NEXT_STEPS_GROUP_NAME && group.items.length > 1),
    );
  }
}
