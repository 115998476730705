/* RESPONSIBLE TEAM: team-workflows */
// eslint-disable-next-line no-restricted-imports
import { getFinStandaloneService } from 'embercom/lib/container-lookup';
import {
  type StepConfigGenerationParams,
  type PathConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { StandaloneInboundPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import InboundBotEditorConfig from './inbound-bot';
import type Step from 'embercom/models/operator/visual-builder/step';
import { stepTypes } from '../../configuration-list';
import HandoffToZendeskAgentConfiguration from '../step/handoff-to-zendesk-agent';
import HandoffToZendeskTicketConfiguration from '../step/handoff-to-zendesk-ticket';
import { NEW_CONVERSATION_TARGET } from 'embercom/lib/operator/custom-bots/constants';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';

export default class StandaloneInboundBotEditorConfig extends InboundBotEditorConfig {
  private finStandaloneService: FinStandaloneService;

  constructor({
    areStepPaywallsActive,
    workflow,
  }: {
    areStepPaywallsActive: boolean;
    workflow?: Workflow;
  }) {
    super({ areStepPaywallsActive, workflow });
    this.finStandaloneService = getFinStandaloneService();
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new StandaloneInboundPathConfig({
      path,
      editorState,
      supportsLocalAttributes: this.supportsLocalAttributes,
      isZendeskConfiguredForIntercomMessenger: this.isZendeskConfiguredForIntercomMessenger,
      isSalesforceConfigured: this.isSalesforceConfigured,
    });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.handoffToZendeskAgent:
        return new HandoffToZendeskAgentConfiguration({
          ...stepConfigArgs,
          tooltipCopy: this.intl.t(
            'operator.workflows.visual-builder.step-menu.handoff-to-zendesk-agent-tooltip-ic-messenger',
          ),
          workflowTarget: NEW_CONVERSATION_TARGET,
        });
      case stepTypes.handoffToZendeskTicket:
        return new HandoffToZendeskTicketConfiguration({
          ...stepConfigArgs,
          shouldCheckZendeskApiConnection: true,
        });
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }

  get isZendeskConfiguredForIntercomMessenger(): boolean {
    return this.finStandaloneService.zendeskConfig?.isConnected || false;
  }

  get isSalesforceConfigured(): boolean {
    return this.finStandaloneService.salesforceConfig?.isConnected || false;
  }

  get supportsSideSheet(): boolean {
    return false;
  }
}
