/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import type ConversationAttributeDescriptor from './conversation-attribute-descriptor';
import { type TicketDescriptor, type TicketType } from 'embercom/objects/inbox/ticket';
import { indexBy } from 'underscore';
import { type SelectedColumn } from 'embercom/services/conversations-table-settings';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { setOwner } from '@ember/application';
import type IntlService from 'ember-intl/services/intl';
import { DEFAULT_TICKET_TYPE_ATTRIBUTES } from 'embercom/lib/inbox/constants';
import { type SortField } from 'embercom/services/inbox-api';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export type ColumnType =
  | 'contact'
  | 'nextBreachTime'
  | 'lastUpdated'
  | 'priority'
  | 'id'
  | 'ticketId'
  | 'adminAssignee'
  | 'teamAssignee'
  | 'state'
  | 'waitingSince'
  | 'channel'
  | 'tags'
  | 'conversationStartedAt'
  | 'userRole'
  | 'location'
  | 'attribute'
  | 'title'
  | 'company'
  | 'ticketAttribute'
  | 'ticketType'
  | 'ticketState'
  | 'github'
  | 'relevantPart'
  | 'description'
  | 'activity'
  | 'ticketCreatedAt'
  | 'category';

export interface ColumnHeaderTooltip {
  title?: string;
  body?: string;
}

export interface ColumnDefinition {
  type: ColumnType;
  fields: string[];
  icon: InterfaceIconName;
  width?: number;
  minWidth?: number;
  label?: string;
  tooltip?: ColumnHeaderTooltip;
  isSortable?: boolean;
  isFixed?: boolean;
  sortField?: SortField;
  isAttribute?: boolean;
  descriptor?: ConversationAttributeDescriptor | TicketDescriptor;
}

export interface ColumnGroup {
  heading: string;
  columns: ColumnDefinition[];
}

export const columnId = (column: ColumnDefinition) =>
  column.isAttribute ? `${column.type}_${column.descriptor?.id}` : column.type;

export const isSameColumn = (firstColumn: ColumnDefinition, secondColumn: ColumnDefinition) =>
  columnId(firstColumn) === columnId(secondColumn);

const DEFAULT_COLUMN_WIDTH = 128;
const DEFAULT_COLUMN_MIN_WIDTH = 105;

export const COLUMN_DEFINITIONS: ColumnDefinition[] = [
  {
    type: 'activity',
    fields: ['last_seen_by_admin_at', 'latest_admin_visible_comment_at'],
    icon: 'activity',
    width: 256,
    minWidth: 128,
  },
  {
    type: 'conversationStartedAt',
    fields: ['conversation_started_at'],
    icon: 'calendar',
    isSortable: true,
    sortField: 'conversation_started_at',
  },
  {
    type: 'lastUpdated',
    fields: ['last_updated'],
    icon: 'activity',
    isSortable: true,
    sortField: 'sorting_updated_at',
  },
  {
    type: 'waitingSince',
    fields: ['waiting_since'],
    icon: 'waiting',
    isSortable: true,
    sortField: 'waiting_since',
  },
  {
    type: 'nextBreachTime',
    fields: ['next_breach_time', 'conversation_sla_states'],
    icon: 'sla',
    isSortable: true,
    sortField: 'next_breach_time',
    width: 96,
  },
  {
    type: 'priority',
    fields: ['priority'],
    icon: 'star',
    isSortable: true,
    sortField: 'priority',
    width: 96,
  },
  {
    type: 'contact',
    fields: ['user_summary', 'last_renderable_part'],
    icon: 'person',
    isFixed: true,
    width: 240,
    minWidth: 128,
  },
  {
    type: 'state',
    fields: ['state'],
    icon: 'opened-message',
    width: 96,
  },
  {
    type: 'adminAssignee',
    fields: ['admin_assignee'],
    icon: 'person',
    width: 160,
    minWidth: 128,
  },
  {
    type: 'teamAssignee',
    fields: ['team_assignee'],
    icon: 'multiple-people',
    width: 160,
    minWidth: 128,
  },
  {
    type: 'userRole',
    fields: ['user_summary'],
    icon: 'person',
  },
  {
    type: 'location',
    fields: ['user_summary'],
    icon: 'location',
  },
  {
    type: 'channel',
    fields: ['channel'],
    icon: 'messenger',
  },
  {
    type: 'tags',
    fields: ['tags_summary'],
    icon: 'tag',
    width: 256,
  },
  {
    type: 'company',
    fields: ['user_summary'],
    icon: 'company',
  },
  {
    type: 'title',
    fields: ['title'],
    icon: 'recipient-data',
  },
  {
    type: 'description',
    fields: ['last_renderable_part'],
    icon: 'ticket',
    width: 256,
    minWidth: 128,
  },
];

export default class ConversationsTableColumn implements ColumnDefinition {
  readonly type: ColumnType;
  readonly fields: string[];
  readonly icon: InterfaceIconName;
  @tracked width: number;
  readonly minWidth: number;
  readonly label?: string;
  readonly tooltip?: ColumnHeaderTooltip;
  readonly isSortable?: boolean;
  readonly isFixed?: boolean;
  readonly sortField?: SortField;
  readonly isAttribute?: boolean;
  readonly descriptor?: ConversationAttributeDescriptor | TicketDescriptor;

  @tracked isResizing = false;
  @tracked isReordering = false;

  constructor(builder: ColumnDefinition) {
    this.type = builder.type;
    this.fields = builder.fields;
    this.icon = builder.icon;
    this.width = builder?.width || DEFAULT_COLUMN_WIDTH;
    this.minWidth = builder?.minWidth || DEFAULT_COLUMN_MIN_WIDTH;
    this.label = builder.label;
    this.tooltip = builder.tooltip;
    this.isSortable = builder.isSortable;
    this.isFixed = builder.isFixed;
    this.sortField = builder.sortField;
    this.isAttribute = builder.isAttribute;
    this.descriptor = builder.descriptor;
  }

  get id() {
    return columnId(this);
  }
}

export class ConversationsTableColumns {
  @service declare session: Session;
  @service declare intl: IntlService;

  @tracked selectedColumns: SelectedColumn[];
  private allColumnDefinitions: ColumnDefinition[];
  private ticketTypes: TicketType[];

  constructor(
    owner: unknown,
    selectedColumns: SelectedColumn[],
    allColumnDefinitions: ColumnDefinition[],
    ticketTypes: TicketType[],
  ) {
    this.selectedColumns = selectedColumns ?? [];
    this.allColumnDefinitions = allColumnDefinitions;
    this.ticketTypes = ticketTypes;
    setOwner(this, owner);
  }

  get columns(): ConversationsTableColumn[] {
    return this.selectedColumnDefinitions.map((col) => new ConversationsTableColumn(col));
  }

  get hiddenColumns() {
    let selectedColumnIds = new Set(
      this.selectedColumnDefinitions.map((column) => columnId(column)),
    );
    let hiddenColumns = this.allColumnDefinitions.filter(
      (column) => !selectedColumnIds.has(columnId(column)),
    );

    return this.groupColumns(hiddenColumns);
  }

  private get selectedColumnDefinitions() {
    let columnsById = indexBy(this.allColumnDefinitions, (column) => columnId(column));

    return this.selectedColumns.reduce<ColumnDefinition[]>((selectedColumns, { id, width }) => {
      let column = columnsById[id];
      if (column) {
        selectedColumns.push({ ...column, width: width || column.width });
      }
      return selectedColumns;
    }, []);
  }
  private groupColumns(columns: ColumnDefinition[]) {
    let default_ticket_attribute_names = Object.values(DEFAULT_TICKET_TYPE_ATTRIBUTES);

    let ticketTypeById = new Map(this.ticketTypes.map((ticketType) => [ticketType.id, ticketType]));

    let prioritizedColumns: ColumnGroup = {
      heading: '',
      columns: [],
    };
    let generalGroup: ColumnGroup = {
      heading: this.intl.t('inbox.conversations-table.column-selector.column-groups.general'),
      columns: [],
    };
    let conversationAttributesGroup: ColumnGroup = {
      heading: this.intl.t(
        'inbox.conversations-table.column-selector.column-groups.conversation-data',
      ),
      columns: [],
    };
    let ticketAttributeGroups = new Map<number, ColumnGroup>();

    for (let column of columns) {
      let prioritizedColumnTypes = [
        'lastUpdated',
        'activity',
        'conversationStartedAt',
        'waitingSince',
        'nextBreachTime',
        'priority',
      ];

      if (prioritizedColumnTypes.includes(column.type)) {
        prioritizedColumns.columns.push(column);
      } else if (column.type === 'attribute') {
        conversationAttributesGroup.columns.push(column);
      } else if (column.type === 'ticketAttribute') {
        let descriptor = column.descriptor;

        if (!(descriptor && default_ticket_attribute_names.includes(descriptor.name))) {
          let ticketTypeId = (column.descriptor as TicketDescriptor).ticketTypeId;
          let ticketType = ticketTypeById.get(ticketTypeId);

          if (ticketType === undefined) {
            throw new Error(
              `Cannot find ticket type with id (${ticketTypeId}) for attribute of id (${column.descriptor?.id}).`,
            );
          }

          let group = ticketAttributeGroups.get(ticketTypeId) || {
            heading: ticketType.name,
            columns: [],
          };

          group.columns.push(column);
          ticketAttributeGroups.set(ticketTypeId, group);
        }
      } else {
        generalGroup.columns.push(column);
      }
    }

    // Sort columns by label in general group
    let sortColumnsAlphabetically = (columns: ColumnDefinition[]) => {
      return columns.sort((a, b) => {
        let labelA = a.label || a.type;
        let labelB = b.label || b.type;
        return labelA.localeCompare(labelB);
      });
    };
    // Sort columns by order if it exists
    let sortColumnsByOrder = (columns: ColumnDefinition[]) => {
      return columns.sort((a, b) => {
        return (a.descriptor?.order || 0) - (b.descriptor?.order || 0);
      });
    };

    generalGroup.columns = sortColumnsAlphabetically(generalGroup.columns);
    conversationAttributesGroup.columns = sortColumnsByOrder(conversationAttributesGroup.columns);

    return [
      prioritizedColumns,
      generalGroup,
      conversationAttributesGroup,
      ...Array.from(ticketAttributeGroups.values()),
    ];
  }
}

export class ColumnDefinitionsBuilder {
  @service declare session: Session;
  @service declare intl: IntlService;
  private descriptors: ConversationAttributeDescriptor[];
  private ticketTypes: TicketType[];
  private view: 'inbox' | 'linked-tickets' | 'search';

  constructor(
    owner: unknown,
    ticketTypes: TicketType[],
    descriptors: ConversationAttributeDescriptor[],
    view: 'inbox' | 'linked-tickets' | 'search',
  ) {
    setOwner(this, owner);
    this.ticketTypes = ticketTypes;
    this.descriptors = descriptors;
    this.view = view;
  }

  get columnDefinitions(): ColumnDefinition[] {
    let label = (col: ColumnDefinition) =>
      col.isAttribute ? col.label : this.intl.t(`inbox.conversations-table.columns.${col.type}`);

    return [
      ...COLUMN_DEFINITIONS,
      ...this.columnIdDefinition,
      ...this.ticketIdDefinition,
      ...this.ticketCreatedAtDefinition,
      ...this.ticketTypeDefinition,
      ...this.ticketStateDefinition,
      ...this.ticketAttributeColumnDefinitions,
      ...this.githubDefinition,
      ...this.conversationDescriptorColumnDefinition,
      ...this.relevantPartDefinition,
      ...this.systemDefinedAttributeWithoutCategoryDefinitions,
    ].map((column) => {
      return {
        ...column,
        label: label(column),
        isSortable: ['search', 'linked-tickets'].includes(this.view)
          ? column.type === 'lastUpdated'
          : column.isSortable,
      };
    });
  }

  private get allDescriptorColumnDefinitions(): ConversationAttributeDescriptor[] {
    return this.descriptors.rejectBy('archived');
  }

  private getToolTipBodyForSystemDefinedAttribute(descriptorId: string | number) {
    if (descriptorId === 'ticket_category') {
      return this.intl.t('inbox.conversations-table.ticket-category-tooltip');
    } else if (descriptorId === 'linked_customer_reports') {
      return this.intl.t('inbox.conversations-table.linked-customer-reports-tooltip');
    } else {
      return '';
    }
  }

  private get systemDefinedAttributeWithoutCategoryDefinitions(): ColumnDefinition[] {
    return this.allDescriptorColumnDefinitions
      .filter((descriptor) => descriptor.category === null)
      .map((descriptor) => {
        return {
          type: 'category',
          label: descriptor.name,
          icon: descriptor.icon || 'transfer',
          isSortable: true,
          sortField: `${descriptor.id}` as `${number}`,
          fields: ['attributes'],
          isAttribute: true,
          descriptor,
          tooltip: {
            title: descriptor.name,
            body: this.getToolTipBodyForSystemDefinedAttribute(descriptor.id),
          },
        };
      });
  }

  private get conversationDescriptorColumnDefinition(): ColumnDefinition[] {
    return this.allDescriptorColumnDefinitions
      .filter((descriptor) => descriptor.category === 'conversation')
      .map((descriptor) => {
        return {
          type: 'attribute',
          label: descriptor.name,
          icon: 'transfer',
          isSortable: true,
          sortField: `${descriptor.id}` as `${number}`,
          fields: ['attributes'],
          isAttribute: true,
          descriptor,
        };
      });
  }

  private get ticketAttributeColumnDefinitions(): ColumnDefinition[] {
    let ticketAttributes = this.ticketTypes.flatMap(({ name, descriptors }) => {
      if (descriptors === null) {
        return [];
      }

      return descriptors.rejectBy('archived').map((descriptor) => {
        return {
          type: 'ticketAttribute' as const,
          label: descriptor.name,
          tooltip: { title: name, body: descriptor.description || undefined },
          icon: 'transfer' as const,
          isSortable: descriptor.type !== 'files',
          sortField: `${descriptor.id}` as const,
          fields: ['attributes'],
          isAttribute: true,
          descriptor,
        };
      });
    });

    return ticketAttributes;
  }

  private get ticketTypeDefinition(): ColumnDefinition[] {
    return [
      {
        type: 'ticketType',
        fields: ['ticket_type'],
        icon: 'ticket',
        width: 160,
      },
    ];
  }

  private get ticketCreatedAtDefinition(): ColumnDefinition[] {
    return [
      {
        type: 'ticketCreatedAt',
        fields: ['ticket_created_at'],
        icon: 'submitted',
        width: 96,
        sortField: 'ticket_created_at',
        isSortable: true,
        tooltip: {
          title: this.intl.t('inbox.conversations-table.columns.ticketCreatedAt'),
          body: this.intl.t('inbox.conversations-table.ticket-created-at-tooltip'),
        },
      },
    ];
  }

  private get ticketStateDefinition(): ColumnDefinition[] {
    return [
      {
        type: 'ticketState',
        fields: ['ticket_state'],
        icon: 'ticket',
        width: 256,
        isSortable: false,
        sortField: 'ticket_state',
      },
    ];
  }

  private get githubDefinition(): ColumnDefinition[] {
    if (this.session.workspace.isFeatureEnabled('inbox-github-linking')) {
      return [
        {
          type: 'github',
          fields: ['github_links'],
          icon: 'github',
          width: 256,
        },
      ];
    } else {
      return [];
    }
  }

  private get ticketIdDefinition(): ColumnDefinition[] {
    return [
      {
        type: 'ticketId',
        fields: ['ticket_id'],
        icon: 'ticket',
        width: 96,
        sortField: 'ticket_id',
        isSortable: true,
        tooltip: {
          title: this.intl.t('inbox.conversations-table.columns.ticketId'),
          body: this.intl.t('inbox.conversations-table.ticket-id-tooltip'),
        },
      },
    ];
  }

  private get columnIdDefinition(): ColumnDefinition[] {
    return [
      {
        type: 'id',
        fields: ['id'],
        icon: 'hash',
        width: 96,
        isSortable: true,
        sortField: 'id',
        tooltip: {
          title: this.intl.t('inbox.conversations-table.columns.id'),
          body: this.intl.t('inbox.conversations-table.column-id-tooltip'),
        },
      },
    ];
  }

  private get relevantPartDefinition(): ColumnDefinition[] {
    if (this.view === 'search') {
      return [
        {
          type: 'relevantPart',
          fields: ['relevant_part'],
          icon: 'search' as const,
          width: 340,
          isFixed: true,
        },
      ];
    }
    return [];
  }
}
