/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-data-foundations */

import EditorConfig, {
  type StepConfigGenerationParams,
  type AttributeGroupList,
  type AttributeGroup,
  type PathConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { WebhookReceivedPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import { WebhookReceivedNoteConfiguration } from '../step/note';
import { WebhookReceivedWorkflowConnectorConfiguration } from '../step/workflow-connector';
import { WebhookReceivedChatMessageConfiguration } from '../step/chat-message';
import { stepTypes } from '../../configuration-list';
import type Step from 'embercom/models/operator/visual-builder/step';
import type InboundTrigger from 'embercom/models/workflow-connector/inbound-trigger';
import type AttributeDescriptor from 'embercom/models/workflow-connector/attribute-descriptor';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
export default class WebhookReceivedEditorConfig extends EditorConfig {
  private readonly webhookReceivedAttributesGroup: AttributeGroup | undefined;
  public readonly canBeTicketTrigger = true;

  constructor({
    areStepPaywallsActive,
    inboundTrigger,
    workflow,
  }: {
    areStepPaywallsActive?: boolean;
    inboundTrigger: InboundTrigger;
    workflow?: Workflow;
  }) {
    super({ areStepPaywallsActive, workflow });

    if (inboundTrigger) {
      this.webhookReceivedAttributesGroup = {
        heading: inboundTrigger.name,
        attributes: inboundTrigger.attributeDescriptors.map((descriptor: AttributeDescriptor) =>
          this.attributeService.webhookReceivedAttributes.find(
            (attribute: AttributeDescriptor) => attribute.id === descriptor.attributeIdentifier,
          ),
        ),
      };
    }
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new WebhookReceivedPathConfig({
      path,
      editorState,
      supportsLocalAttributes: this.supportsLocalAttributes,
    });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };
    let webhookReceivedConfigAttribute: any = {
      ...stepConfigArgs,
      attributeService: this.attributeService,
    };
    if (this.webhookReceivedAttributesGroup) {
      webhookReceivedConfigAttribute = {
        ...webhookReceivedConfigAttribute,
        webhookReceivedAttributes: this.webhookReceivedAttributesGroup.attributes,
      };
    }

    switch (step.typeKey) {
      case stepTypes.note:
        return new WebhookReceivedNoteConfiguration({
          ...webhookReceivedConfigAttribute,
        });
      case stepTypes.workflowConnector:
        return new WebhookReceivedWorkflowConnectorConfiguration({
          ...webhookReceivedConfigAttribute,
          app: this.app,
        });
      case stepTypes.chatMessage:
        return new WebhookReceivedChatMessageConfiguration({
          ...webhookReceivedConfigAttribute,
        });
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }

  get workflowMatchingTargetingAttributes(): AttributeGroupList {
    let groups = [
      ...this.attributeService.attributesToGroupList(
        this.attributeService.visualBotBuilderConditionalAttributes,
      ),
      ...this.attributeService.ticketAttributesByType,
    ];

    groups = this.excludeCustomObjectAttributes(groups);
    groups = this.excludeMessageAndConversationAttributes(groups);
    groups = this.excludeUserAndCompanyAttributes(groups);
    groups = this.excludeAiAgentAttributes(groups);

    if (this.webhookReceivedAttributesGroup) {
      groups = [...groups, this.webhookReceivedAttributesGroup];
    }

    return groups;
  }

  get runtimeMatchingTargetingAttributes(): AttributeGroupList {
    let groups = [...super.runtimeMatchingTargetingAttributes];

    groups = this.excludeCustomObjectAttributes(groups);
    groups = this.excludeMessageAndConversationAttributes(groups);
    groups = this.excludeUserAndCompanyAttributes(groups);
    groups = this.excludeAiAgentAttributes(groups);

    if (this.webhookReceivedAttributesGroup) {
      groups = [...groups, this.webhookReceivedAttributesGroup];
    }

    return groups;
  }

  get supportsConversationChannelTargeting(): boolean {
    return false;
  }

  get supportsUserTargeting(): boolean {
    return false;
  }

  private excludeCustomObjectAttributes(groups: AttributeGroupList): AttributeGroupList {
    let customObjectGroupHeadings = this.attributeService.customObjectAttributeGroups.map(
      (group: AttributeGroup) => group.heading,
    );
    return this.excludeGroupsWithHeadings(groups, customObjectGroupHeadings);
  }

  private excludeMessageAndConversationAttributes(groups: AttributeGroupList): AttributeGroupList {
    return this.excludeGroupsWithHeadings(groups, ['Conversation data', 'Message data']);
  }

  private excludeUserAndCompanyAttributes(groups: AttributeGroupList): AttributeGroupList {
    return this.excludeGroupsWithHeadings(groups, ['Person data', 'Company data']);
  }

  private excludeAiAgentAttributes(groups: AttributeGroupList): AttributeGroupList {
    return this.excludeGroupsWithHeadings(groups, ['AI Agent data']);
  }

  private excludeGroupsWithHeadings(
    groups: AttributeGroupList,
    headings: string[],
  ): AttributeGroupList {
    return groups.filter((group) => !group.heading || !headings.includes(group.heading));
  }
}
