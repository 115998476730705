/* RESPONSIBLE TEAM: team-phone */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

interface WireFormatCall {
  id: string;
  type: string;
  direction: string;
  state: string;
  external_id: string | null;
  admin: AdminSummaryWireFormat;
  initiated_at: Date;
  answered_at: Date | null;
  ended_at: Date | null;
  duration: number | null;
  recording_enabled: boolean;
  recording_url: string | null;
  transcript_url: string | null;
  ended_reason: string | null;
  deflection_type: string | null;
  deflection_trigger: string | null;
  recording_name: string | null;
  forwarded: boolean;
  externally_transferred_to: string | null;
  detailed_state: string | null;
  is_recorded: boolean;
  csat_rating: string | null;
  call_listening_enabled: boolean;
  manual_answer_enabled: boolean;
  assigned_admin_id: number | null;
}

export interface Call {
  id: string;
  type: string;
  direction: string;
  state: string;
  externalId: string | null;
  admin: AdminSummaryWireFormat;
  initiatedAt: Date;
  answeredAt: Date | null;
  endedAt: Date | null;
  duration: number | null;
  recordingEnabled: boolean;
  recordingUrl: string | null;
  transcriptUrl: string | null;
  endedReason: string | null;
  deflectionType: string | null;
  deflectionTrigger: string | null;
  recordingName: string | null;
  forwarded: boolean;
  externallyTransferredTo: string | null;
  detailedState: string | null;
  isRecorded: boolean;
  csatRating: string | null;
  callListeningEnabled: boolean;
  manualAnswerEnabled: boolean;
  assignedAdminId: number | null;
}

export interface CallSummaryWireFormat {
  renderable_type: RenderableType;
  call: WireFormatCall;
  readonly blocks: Array<object>;
}

export default class CallSummary implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.CallSummary;
  readonly blocks?: Array<object>;
  readonly call: Call;

  get id() {
    return this.call.id;
  }

  get type() {
    return this.call.type;
  }

  get direction() {
    return this.call.direction;
  }

  get state() {
    return this.call.state;
  }

  get externalId() {
    return this.call.externalId;
  }

  get admin() {
    return this.call.admin;
  }

  get initiatedAt() {
    return this.call.initiatedAt;
  }

  get answeredAt() {
    return this.call.answeredAt;
  }

  get endedAt() {
    return this.call.endedAt;
  }

  get duration() {
    return this.call.duration;
  }

  get recordingEnabled() {
    return this.call.recordingEnabled;
  }

  get recordingUrl() {
    return this.call.recordingUrl;
  }

  get transcriptUrl() {
    return this.call.transcriptUrl;
  }

  get endedReason() {
    return this.call.endedReason;
  }

  get deflectionType() {
    return this.call.deflectionType;
  }

  get deflectionTrigger() {
    return this.call.deflectionTrigger;
  }

  get recordingName() {
    return this.call.recordingName;
  }

  get forwarded() {
    return this.call.forwarded;
  }

  get externallyTransferredTo() {
    return this.call.externallyTransferredTo;
  }

  get detailedState() {
    return this.call.detailedState;
  }

  get isRecorded() {
    return this.call.isRecorded;
  }

  get csatRating() {
    return this.call.csatRating;
  }

  get callListeningEnabled() {
    return this.call.callListeningEnabled;
  }

  get manualAnswerEnabled() {
    return this.call.manualAnswerEnabled;
  }

  get assignedAdminId() {
    return this.call.assignedAdminId;
  }

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  get styles() {
    return {
      backgroundColor: 'bg-notice-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-notice-container-emphasis',
    };
  }

  get hasTranscriptSummary() {
    return !!this.blocks?.length;
  }

  constructor(call: WireFormatCall, blocks?: Array<object>) {
    this.call = {
      id: call.id,
      type: call.type,
      direction: call.direction,
      state: call.state,
      externalId: call.external_id,
      admin: call.admin,
      initiatedAt: call.initiated_at,
      answeredAt: call.answered_at,
      endedAt: call.ended_at,
      duration: call.duration,
      recordingEnabled: call.recording_enabled,
      recordingUrl: call.recording_url,
      transcriptUrl: call.transcript_url,
      endedReason: call.ended_reason,
      deflectionType: call.deflection_type,
      deflectionTrigger: call.deflection_trigger,
      recordingName: call.recording_name,
      forwarded: call.forwarded,
      externallyTransferredTo: call.externally_transferred_to,
      detailedState: call.detailed_state,
      isRecorded: call.is_recorded,
      csatRating: call.csat_rating,
      callListeningEnabled: call.call_listening_enabled,
      manualAnswerEnabled: call.manual_answer_enabled,
      assignedAdminId: call.assigned_admin_id,
    };
    this.blocks = blocks;
  }

  static deserialize(json: CallSummaryWireFormat): CallSummary {
    return new CallSummary(json.call, json.blocks);
  }
}
