/* RESPONSIBLE TEAM: team-channels */

export interface EmailHeaderAddress {
  name?: string;
  email?: string;
}

export class HeaderAddresses {
  from?: EmailHeaderAddress[];
  to?: EmailHeaderAddress[];
  cc?: EmailHeaderAddress[];
  bcc?: EmailHeaderAddress[];
  reply_to?: EmailHeaderAddress[];

  constructor(
    from?: EmailHeaderAddress[],
    to?: EmailHeaderAddress[],
    cc?: EmailHeaderAddress[],
    bcc?: EmailHeaderAddress[],
    replyTo?: EmailHeaderAddress[],
  ) {
    this.from = from;
    this.to = to;
    this.cc = cc;
    this.bcc = bcc;
    this.reply_to = replyTo;
  }
}

export interface EmailMetadataWireFormat {
  id?: number;
  message_id?: string;
  subject?: string;
  date?: string;
  header_addresses?: HeaderAddresses;
  external_sender?: boolean;
  has_history?: boolean;
}

export class EmailMetadata {
  id?: number;
  messageId?: string;
  subject?: string;
  date?: string;
  headerAddresses?: HeaderAddresses;
  externalSender?: boolean;
  hasHistory?: boolean;

  constructor(
    id?: number,
    messageId?: string,
    subject?: string,
    date?: string,
    headerAddresses?: HeaderAddresses,
    externalSender?: boolean,
    hasHistory?: boolean,
  ) {
    this.id = id;
    this.messageId = messageId;
    this.subject = subject;
    this.date = date;
    this.headerAddresses = headerAddresses;
    this.externalSender = externalSender;
    this.hasHistory = hasHistory;
  }

  static deserialize(json: EmailMetadataWireFormat): EmailMetadata {
    return new EmailMetadata(
      json.id,
      json.message_id,
      json.subject,
      json.date,
      json.header_addresses,
      json.external_sender,
      json.has_history,
    );
  }
}
