/* RESPONSIBLE TEAM: team-ai-agent-2 */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

export class CustomAnswerAppliedData {
  id: number;
  title: string;

  constructor(id: number, title: string) {
    this.id = id;
    this.title = title;
  }

  static deserialize(json: CustomAnswerAppliedDataWireFormat): CustomAnswerAppliedData {
    return new CustomAnswerAppliedData(json.id, json.title);
  }
}

export interface CustomAnswerAppliedDataWireFormat {
  id: number;
  title: string;
}

export interface CustomAnswerAppliedWireFormat {
  renderable_type: RenderableType;
  admin_summary: AdminSummaryWireFormat;
  custom_answer: CustomAnswerAppliedDataWireFormat;
}

export default class CustomAnswerApplied {
  readonly renderableType: RenderableType = RenderableType.CustomAnswerApplied;
  readonly customAnswer: CustomAnswerAppliedData;
  readonly adminSummary: AdminSummary;

  constructor(adminSummary: AdminSummary, customAnswerApplied: CustomAnswerAppliedData) {
    this.adminSummary = adminSummary;
    this.customAnswer = customAnswerApplied;
  }

  get creatingEntity() {
    return { ...this.adminSummary, type: EntityType.Bot };
  }

  static deserialize(json: CustomAnswerAppliedWireFormat): CustomAnswerApplied {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    let customAnswer = CustomAnswerAppliedData.deserialize(json.custom_answer);

    return new CustomAnswerApplied(adminSummary, customAnswer);
  }
}
