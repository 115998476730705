/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import ComponentConfiguration from 'embercom/objects/component-configuration';
import { assert } from '@ember/debug';
import { getIntlService } from 'embercom/lib/container-lookup';
import type PathConfig from './path';
import type Step from 'embercom/models/operator/visual-builder/step';
import type IntlService from 'embercom/services/intl';
/* eslint-disable-next-line ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export type StepConfigParams = {
  component: $TSFixMe; // keyof Registry, this requires *all* step components to be typed
  pathConfig: PathConfig;
  step: Step;
};

type StepConfigOption = {
  icon: string;
  text: string;
  isDisabled?: boolean;
  tooltipText?: string;
  onSelectItem: () => void;
};

export default class StepConfig extends ComponentConfiguration {
  @tracked pathConfig: PathConfig;
  @tracked step: Step;
  intl: IntlService;

  constructor({ component, pathConfig, step }: StepConfigParams) {
    super({ component });

    this.pathConfig = pathConfig;
    this.step = step;
    this.intl = getIntlService();

    assert('You must specify the pathConfig for the step configuration', this.pathConfig);
    assert('You must specify the step for the step configuration', this.step);
  }

  get editorState() {
    return this.pathConfig.editorState;
  }

  get hasContextMenu() {
    return this.stepMenuConfigOptions ? this.stepMenuConfigOptions.length >= 1 : false;
  }

  get stepMenuConfigOptions(): StepConfigOption[] | null {
    return [this.deleteOption];
  }

  get alignment() {
    return this.step.isGroupEnding ? 'right' : 'left';
  }

  // stepMenuConfigOptions - Delete Option
  get deleteOption() {
    return {
      icon: 'trash',
      text: this.intl.t('operator.workflows.visual-builder.step-option-menu.delete'),
      isDisabled: !this.canDeleteStep,
      tooltipText: this.deleteTooltipText,
      onSelectItem: () => this.deleteStep(),
    };
  }

  @computed('pathConfig.path.isStart', 'step.group.{workflow.groups.length,steps.length}')
  get canDeleteStep() {
    return this.pathConfig.canDeleteStep(this.step);
  }

  get deleteTooltipText(): string | undefined {
    return !this.canDeleteStep
      ? this.intl.t('operator.workflows.visual-builder.step-option-menu.delete-step-tooltip')
      : undefined;
  }

  deleteStep() {
    this.pathConfig.deleteStep(this.step);
  }

  get supportsLocalAttributes(): boolean {
    return this.pathConfig.supportsLocalAttributes;
  }
}
