/* RESPONSIBLE TEAM: team-tickets-1 */
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import RenderablePart, { type RenderablePartWireFormat } from './renderable-part';
import TeamSummary, { type TeamSummaryWireFormat } from './team-summary';
import AdminSummary, { type AdminSummaryWireFormat } from './admin-summary';
import { ConversationState, TicketSystemState } from './conversation';
import ConversationAttributeSummary, {
  type ConversationAttributeSummaryWireFormat,
} from './conversation-attribute-summary';
import {
  type Channel,
  ChannelData,
  type ChannelDataWireFormat,
} from 'embercom/models/data/inbox/channels';
import Tag, { type TagWireFormat } from './tag';
import { tracked } from '@glimmer/tracking';
import { TicketCategory, TicketType, type TicketTypeWireFormat } from './ticket';
import GithubLinkSummary, {
  type GithubLinkSummaryWireFormat,
} from 'embercom/objects/inbox/github-link-summary';
import LinkedTicketSummary, {
  type LinkedTicketSummaryConstructorFormat,
  type LinkedTicketSummaryWireFormat,
} from './linked-ticket-summary';
import { DEFAULT_TICKET_TYPE_ATTRIBUTES } from 'embercom/lib/inbox/constants';
import { type EntityType } from 'embercom/models/data/entity-types';
import ParticipantUserSummary from './participant-user-summary';
import ConversationSlaState, {
  type ConversationSlaStateWireFormat,
} from 'embercom/objects/inbox/conversation-sla-state';
import moment from 'moment-timezone';
import { type RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { firstConversationParticipant } from './participant-user-summary';

export interface TableEntryHighlights {
  title?: string[];
  ticketTitle?: string[];
  ticketDescription?: string[];
  parts?: {
    partId: number;
    entityId: number;
    entityType: EntityType;
    matches: string[];
  }[];
}

interface TableEntryHighlightsWireFormat {
  title?: string[];
  ticket_title?: string[];
  ticket_description?: string[];
  parts?: {
    part_id: number;
    entity_id: number;
    entity_type: EntityType;
    matches: string[];
  }[];
}

export interface ConversationTableEntryConstructorFormat {
  id: number;
  title?: string;
  user: UserSummary;
  participantSummaries: ParticipantUserSummary[];
  priority?: boolean;
  redacted: boolean;
  lastUpdated: Date;
  lastRenderableSummaryPart?: RenderablePart;
  lastAdminMentionedPart?: RenderablePart;
  nextBreachTime?: Date;
  conversationSlaStates?: ConversationSlaState[];
  adminAssignee?: AdminSummary;
  teamAssignee?: TeamSummary;
  state?: ConversationState;
  ticketState?: TicketSystemState;
  ticketCustomStateId?: number;
  attributes?: ConversationAttributeSummary[];
  waitingSince?: Date;
  channel?: ChannelData;
  tags: Tag[];
  lastSeenByAdminAt?: Date;
  latestAdminVisibleCommentAt?: Date;
  conversationStartedAt?: Date;
  ticketType?: TicketType;
  githubLinks?: GithubLinkSummary[];
  canReplyToUser: boolean;
  visibleToUser?: boolean;
  parent?: LinkedTicketSummaryConstructorFormat;
  isInboundConversation?: boolean;
  relevantPart?: RenderablePart;
  highlights?: TableEntryHighlights;
  createdAt?: Date;
  ticketId?: string;
  ticketCategory?: TicketCategory;
  ticketCreatedAt?: Date;
  linkedConversationIds: number[] | null;
  linkedCustomerTicketIds: number[] | null;
  companyId?: string;
}

export interface ConversationTableEntryWireFormat {
  id: number;
  title?: string;
  user_summary: UserSummaryWireFormat;
  participant_summaries?: UserSummaryWireFormat[];
  priority?: boolean;
  last_updated: string;
  last_renderable_part?: RenderablePartWireFormat;
  last_admin_mentioned_part?: RenderablePartWireFormat | null;
  first_user_part?: { renderable_data: { channel: Channel } };
  next_breach_time?: string;
  conversation_sla_states?: ConversationSlaStateWireFormat[];
  admin_assignee?: AdminSummaryWireFormat;
  team_assignee?: TeamSummaryWireFormat;
  state?: ConversationState;
  ticket_state?: TicketSystemState;
  ticket_custom_state_id?: number;
  attributes?: ConversationAttributeSummaryWireFormat[];
  waiting_since?: string;
  tags_summary?: TagWireFormat[];
  last_seen_by_admin_at?: string;
  latest_admin_visible_comment_at?: string;
  conversation_started_at?: string;
  channel: ChannelDataWireFormat;
  ticket_type?: TicketTypeWireFormat;
  redacted: boolean;
  github_links?: GithubLinkSummaryWireFormat[];
  can_reply_to_user: boolean;
  visible_to_user?: boolean;
  parent?: LinkedTicketSummaryWireFormat;
  is_inbound_conversation?: boolean;
  relevant_part?: RenderablePartWireFormat;
  highlights?: TableEntryHighlightsWireFormat;
  created_at?: string;
  ticket_id?: string;
  ticket_category?: TicketCategory;
  ticket_created_at?: string;
  linked_conversation_ids: number[] | null;
  linked_customer_ticket_ids: number[] | null;
  company_id?: string;
}

export default class ConversationTableEntry {
  readonly id: number;
  readonly title?: string;
  readonly user: UserSummary;
  readonly participantSummaries: ParticipantUserSummary[];
  @tracked priority?: boolean;
  readonly redacted: boolean;
  @tracked lastUpdated: Date;
  @tracked lastRenderableSummaryPart?: RenderablePart;
  readonly lastAdminMentionedPart?: RenderablePart;
  @tracked adminAssignee?: AdminSummary;
  @tracked teamAssignee?: TeamSummary;
  @tracked state?: ConversationState;
  @tracked ticketState?: TicketSystemState;
  @tracked ticketCustomStateId?: number;
  @tracked attributes?: ConversationAttributeSummary[];
  readonly waitingSince?: Date;
  @tracked channel?: ChannelData;
  readonly tags: Tag[];
  readonly lastSeenByAdminAt?: Date;
  readonly latestAdminVisibleCommentAt?: Date;
  readonly conversationStartedAt?: Date;
  readonly isTicket: boolean = false;
  @tracked ticketType?: TicketType;
  @tracked isRead = false;
  @tracked visibleToUser = false;
  @tracked canReplyToUser: boolean;
  readonly githubLinks?: GithubLinkSummary[];
  @tracked parent?: LinkedTicketSummary | LinkedTicketSummaryConstructorFormat;
  @tracked nextBreachTime?: Date;
  @tracked conversationSlaStates?: ConversationSlaState[];
  readonly isInboundConversation?: boolean;
  readonly relevantPart?: RenderablePart;
  readonly highlights?: TableEntryHighlights;
  @tracked linkedConversationIds: number[] | null;
  @tracked linkedCustomerTicketIds: number[] | null;
  @tracked linkedTickets?: LinkedTicketSummary[];
  @tracked createdAt?: Date;
  readonly ticketId?: string;
  readonly ticketCategory?: TicketCategory;
  readonly ticketCreatedAt?: Date;
  readonly companyId?: string;

  get isOpen() {
    return this.state === ConversationState.Open;
  }

  get isSnoozed() {
    return this.state === ConversationState.Snoozed;
  }

  get isClosed() {
    return this.state === ConversationState.Closed;
  }

  constructor(inputs: ConversationTableEntryConstructorFormat) {
    let {
      id,
      title,
      user,
      participantSummaries,
      priority,
      redacted,
      lastUpdated,
      lastRenderableSummaryPart,
      lastAdminMentionedPart,
      nextBreachTime,
      conversationSlaStates,
      adminAssignee,
      teamAssignee,
      state,
      ticketState,
      ticketCustomStateId,
      attributes,
      waitingSince,
      channel,
      tags,
      lastSeenByAdminAt,
      latestAdminVisibleCommentAt,
      conversationStartedAt,
      ticketType,
      githubLinks,
      visibleToUser,
      parent,
      isInboundConversation,
      relevantPart,
      highlights,
      createdAt,
      linkedConversationIds,
      linkedCustomerTicketIds,
      canReplyToUser,
      ticketId,
      ticketCategory,
      ticketCreatedAt,
      companyId,
    } = inputs;
    this.id = id;
    this.title = title;
    this.user = user;
    this.participantSummaries = participantSummaries;
    this.priority = priority;
    this.redacted = redacted;
    this.lastUpdated = lastUpdated;
    this.lastRenderableSummaryPart = lastRenderableSummaryPart;
    this.lastAdminMentionedPart = lastAdminMentionedPart;
    this.nextBreachTime = nextBreachTime;
    this.conversationSlaStates = conversationSlaStates;
    this.adminAssignee = adminAssignee;
    this.teamAssignee = teamAssignee;
    this.state = state;
    this.ticketState = ticketState;
    this.ticketCustomStateId = ticketCustomStateId;
    this.attributes = attributes;
    this.waitingSince = waitingSince;
    this.channel = channel;
    this.tags = tags;
    this.lastSeenByAdminAt = lastSeenByAdminAt;
    this.latestAdminVisibleCommentAt = latestAdminVisibleCommentAt;
    this.conversationStartedAt = conversationStartedAt;
    this.ticketType = ticketType;
    this.isTicket = !!ticketType;
    this.githubLinks = githubLinks;
    this.visibleToUser = visibleToUser ?? false;
    this.parent = parent;
    this.isInboundConversation = isInboundConversation;
    this.relevantPart = relevantPart;
    this.highlights = highlights;
    this.createdAt = createdAt || undefined;
    this.linkedConversationIds = linkedConversationIds;
    this.linkedCustomerTicketIds = linkedCustomerTicketIds;
    this.canReplyToUser = canReplyToUser;
    this.ticketId = ticketId;
    this.ticketCategory = ticketCategory;
    this.ticketCreatedAt = ticketCreatedAt;
    this.companyId = companyId;

    if (this.lastSeenByAdminAt && this.latestAdminVisibleCommentAt) {
      if (this.lastRenderableSummaryPart) {
        this.isRead = this.lastSeenByAdminAt >= this.lastRenderableSummaryPart.createdAt;
      } else {
        this.isRead = this.lastSeenByAdminAt >= this.latestAdminVisibleCommentAt;
      }
    }
  }

  static deserialize(json: ConversationTableEntryWireFormat) {
    let lastRenderableSummaryPart = json.last_renderable_part
      ? RenderablePart.deserialize(json.last_renderable_part)
      : undefined;
    let lastAdminMentionedPart = json.last_admin_mentioned_part
      ? RenderablePart.deserialize(json.last_admin_mentioned_part)
      : undefined;
    let nextBreachTime = json.next_breach_time ? new Date(json.next_breach_time) : undefined;
    let conversationSlaStates = json.conversation_sla_states
      ? json.conversation_sla_states.map(ConversationSlaState.deserialize)
      : undefined;
    let waitingSince = json.waiting_since ? new Date(json.waiting_since) : undefined;
    let user = UserSummary.deserialize(json.user_summary);
    let participantSummaries = json.participant_summaries
      ? json.participant_summaries.compact().map(ParticipantUserSummary.deserialize)
      : undefined;
    let adminAssignee = json.admin_assignee
      ? AdminSummary.deserialize(json.admin_assignee)
      : undefined;

    let teamAssignee = json.team_assignee ? TeamSummary.deserialize(json.team_assignee) : undefined;

    let attributes = json.attributes
      ? json.attributes.map((attribute) => ConversationAttributeSummary.deserialize(attribute))
      : undefined;

    let channel = json.channel ? ChannelData.deserialize(json.channel) : undefined;

    let tags = json.tags_summary ? json.tags_summary.map(Tag.deserialize) : [];

    let lastSeenByAdminAt = json.last_seen_by_admin_at
      ? new Date(json.last_seen_by_admin_at)
      : undefined;
    let latestAdminVisibleCommentAt = json.latest_admin_visible_comment_at
      ? new Date(json.latest_admin_visible_comment_at)
      : undefined;

    let conversationStartedAt = json.conversation_started_at
      ? new Date(json.conversation_started_at)
      : undefined;

    let createdAt = json.created_at ? new Date(json.created_at) : undefined;

    let ticketType = json.ticket_type ? TicketType.deserialize(json.ticket_type) : undefined;

    let githubLinks = json.github_links
      ? json.github_links.map(GithubLinkSummary.deserialize)
      : undefined;

    let parent = json.parent ? LinkedTicketSummary.deserialize(json.parent) : undefined;
    let relevantPart = json.relevant_part
      ? RenderablePart.deserialize(json.relevant_part)
      : undefined;

    let highlights: TableEntryHighlights | undefined = json.highlights
      ? {
          title: json.highlights.title,
          ticketTitle: json.highlights.ticket_title,
          ticketDescription: json.highlights.ticket_description,
          parts: json.highlights.parts?.map((part) => ({
            partId: part.part_id,
            entityId: part.entity_id,
            entityType: part.entity_type,
            matches: part.matches,
          })),
        }
      : undefined;

    let linkedConversationIds = json.linked_conversation_ids;
    let linkedCustomerTicketIds = json.linked_customer_ticket_ids;
    let ticketCreatedAt = json.ticket_created_at ? new Date(json.ticket_created_at) : undefined;

    let companyId = ticketType ? json.company_id : user?.company?.id;

    return new ConversationTableEntry({
      id: json.id,
      title: json.title,
      user,
      participantSummaries: participantSummaries ?? [],
      priority: json.priority,
      redacted: json.redacted,
      lastUpdated: new Date(json.last_updated),
      lastRenderableSummaryPart,
      lastAdminMentionedPart,
      nextBreachTime,
      conversationSlaStates,
      adminAssignee,
      teamAssignee,
      state: json.state,
      ticketState: json.ticket_state,
      ticketCustomStateId: json?.ticket_custom_state_id,
      attributes,
      waitingSince,
      channel,
      tags,
      lastSeenByAdminAt,
      latestAdminVisibleCommentAt,
      conversationStartedAt,
      ticketType,
      githubLinks,
      visibleToUser: json.visible_to_user,
      parent,
      isInboundConversation: json.is_inbound_conversation,
      relevantPart,
      highlights,
      createdAt,
      linkedConversationIds,
      linkedCustomerTicketIds,
      canReplyToUser: json.can_reply_to_user,
      ticketId: json.ticket_id,
      ticketCategory: json.ticket_category,
      ticketCreatedAt,
      companyId,
    });
  }

  markAsRead(): void {
    this.isRead = true;
  }

  get companyName() {
    return this.user?.company?.name;
  }

  get isCustomerTicket(): boolean {
    return this.ticketCategory === TicketCategory.Request;
  }

  get isTrackerTicket(): boolean {
    return this.ticketCategory === TicketCategory.Tracker;
  }

  get isBackOfficeTicket(): boolean {
    return this.ticketCategory === TicketCategory.Task;
  }

  get isChild() {
    return this.parent;
  }

  get isReplyable(): boolean {
    return this.canReplyToUser;
  }

  get isNotAssigned() {
    return (
      (!this.adminAssignee || this.adminAssignee.isUnassignedAssignee) &&
      (!this.teamAssignee || this.teamAssignee.isUnassignedAssignee)
    );
  }

  get linkedCustomerReportIds() {
    return (this.linkedConversationIds || []).concat(this.linkedCustomerTicketIds || []);
  }

  isAssignedTo(entityId: number): boolean {
    return this.adminAssignee?.id === entityId || this.teamAssignee?.id === entityId;
  }

  get ticketTitle() {
    if (!this.isTicket) {
      return '';
    }

    let titleAttribute = this.attributes?.find(
      (attr) => attr.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.TITLE,
    );

    if (titleAttribute) {
      return titleAttribute.value as string;
    }
    return this.ticketType?.name;
  }

  get ticketDescription() {
    if (!this.isTicket) {
      return '';
    }

    let descriptionAttribute = this.attributes?.find(
      (attr) => attr.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.DESCRIPTION,
    );

    if (descriptionAttribute) {
      return descriptionAttribute.value;
    }
    return '';
  }

  get ticketDescriptionAsBlocks() {
    let description = this.ticketDescription;

    if (description === '') {
      return [];
    }

    if (typeof description === 'string') {
      try {
        return JSON.parse(description);
      } catch {
        return [];
      }
    }

    return [];
  }

  get isTicketResolved(): boolean {
    return this.isTicket && this.ticketState === TicketSystemState.Resolved;
  }

  hasAttributeForDescriptor(id: number | string) {
    return this.attributes?.any((attribute) => attribute.descriptor.id === id);
  }

  addAttribute(attribute: ConversationAttributeSummary) {
    if (!this.attributes) {
      return;
    }

    if (this.hasAttributeForDescriptor(attribute.descriptor.id)) {
      return;
    }

    this.attributes = [...this.attributes, attribute];
  }

  get hasMultipleParticipants() {
    return this.participantSummaries.length > 1;
  }

  get remainingParticipantsCount() {
    return this.participantSummaries.length - 1;
  }

  isLastPart(type: RenderableType) {
    return this.lastRenderableSummaryPart?.renderableType === type;
  }

  isLastPartCreatedWithin({ seconds }: { seconds: number }) {
    if (!this.lastRenderableSummaryPart) {
      return false;
    }

    return moment().diff(moment(this.lastRenderableSummaryPart.createdAt), 'second') <= seconds;
  }

  get firstParticipant(): UserSummary {
    return firstConversationParticipant(this.participantSummaries) ?? this.user;
  }
}

export const REQUIRED_CONVERSATIONS_TABLE_FIELDS = [
  'state',
  'ticket_state',
  'ticket_custom_state_id',
  'ticket_type',
  'last_updated',
  'visible_to_user',
  'parent',
  'user_summary',
  'participant_summaries',
  'attributes',
] as const;
