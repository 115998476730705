/* RESPONSIBLE TEAM: team-ai-agent-2 */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

export class CustomisationSettings {
  aiToneOfVoice: string;
  aiLanguageStyle: string;
  aiAnswerLength: string;

  constructor(aiToneOfVoice: string, aiLanguageStyle: string, aiAnswerLength: string) {
    this.aiToneOfVoice = aiToneOfVoice;
    this.aiLanguageStyle = aiLanguageStyle;
    this.aiAnswerLength = aiAnswerLength;
  }

  static deserialize(json: CustomisationSettingsWireFormat): CustomisationSettings {
    return new CustomisationSettings(
      json.ai_tone_of_voice,
      json.ai_language_style,
      json.ai_answer_length,
    );
  }
}

export interface CustomisationSettingsWireFormat {
  ai_tone_of_voice: string;
  ai_language_style: string;
  ai_answer_length: string;
}

export interface FinCustomisationSettingsAppliedWireFormat {
  renderable_type: RenderableType;
  admin_summary: AdminSummaryWireFormat;
  fin_customisation_settings: CustomisationSettingsWireFormat;
}

export default class FinCustomisationSettingsApplied {
  readonly renderableType: RenderableType = RenderableType.FinCustomisationSettingsApplied;
  readonly finCustomisationSettings: CustomisationSettings;
  readonly adminSummary: AdminSummary;

  constructor(adminSummary: AdminSummary, finCustomisationSettings: CustomisationSettings) {
    this.adminSummary = adminSummary;
    this.finCustomisationSettings = finCustomisationSettings;
  }

  get creatingEntity() {
    return { ...this.adminSummary, type: EntityType.Bot };
  }

  static deserialize(
    json: FinCustomisationSettingsAppliedWireFormat,
  ): FinCustomisationSettingsApplied {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    let customisationSettings = CustomisationSettings.deserialize(json.fin_customisation_settings);

    return new FinCustomisationSettingsApplied(adminSummary, customisationSettings);
  }
}
