/* RESPONSIBLE TEAM: team-workflows */

import AdminSummary, { type AdminSummaryWireFormat } from './admin-summary';

export interface OperatorWorkflowEventDataWireFormat {
  admin?: AdminSummaryWireFormat;
  wait_started?: WaitStartedWireFormat;
  skip_assignment_action?: SkipAssignmentActionWireFormat;
  workflow_finished?: WorkflowFinishedWireFormat;
  action_data?: ActionDataWireFormat;
  language_override_updated?: LanguageOverrideUpdatedWireFormat;
  user_inactivity_conversation_autoclosed?: UserInactivityConversationAutoClosedWireFormat;
  ai_agent_ignoring_user_message?: AiAgentIgnoringUserMessageWireFormat;
  ai_agent_workflow_started?: AiAgentWorkflowStartedWireFormat;
  ai_agent_task_started?: AiAgentTaskStartedWireFormat;
  ai_agent_task_finished?: AiAgentTaskFinishedWireFormat;
  ai_agent_task_failed?: AiAgentTaskFailedWireFormat;
  ai_agent_task_step_reasoning?: AiAgentTaskStepReasoningWireFormat;
  ai_agent_set_attributes?: AiAgentSetAttributesWireFormat;
  email_verification_successful?: EmailVerificationSuccessfulWireFormat;
  email_verification_failed?: EmailVerificationFailedWireFormat;
  email_already_verified?: EmailAlreadyVerifiedWireFormat;
  email_triggered_for_verification?: EmailTriggeredForVerificationWireFormat;
}

interface WaitStarted {
  durationType: string;
  customDuration: number | null;
}
type WaitStartedWireFormat = KeysToSnakeCase<WaitStarted>;

interface SkipAssignmentAction {
  outboundId: number | undefined;
  outboundType: number;
  outboundName: string;
}
type SkipAssignmentActionWireFormat = KeysToSnakeCase<SkipAssignmentAction>;

interface WorkflowFinished {
  reason: string;
}
type WorkflowFinishedWireFormat = KeysToSnakeCase<WorkflowFinished>;

interface ActionData {
  tagId?: string;
  attributeIdentifier?: string;
  value?: string;
}
type ActionDataWireFormat = KeysToSnakeCase<ActionData>;
interface LanguageOverrideUpdated {
  previousValue: string | null;
  newValue: string;
}
type LanguageOverrideUpdatedWireFormat = KeysToSnakeCase<LanguageOverrideUpdated>;

interface UserInactivityConversationAutoClosed {
  endUserInactivityTimer: number;
  identifier: string;
}
type UserInactivityConversationAutoClosedWireFormat =
  KeysToSnakeCase<UserInactivityConversationAutoClosed>;

interface AiAgentIgnoringUserMessage {
  reason: string;
}
type AiAgentIgnoringUserMessageWireFormat = KeysToSnakeCase<AiAgentIgnoringUserMessage>;

interface AiAgentWorkflowStarted {
  title: string;
  id?: number;
  rulesetId: number;
}

type AiAgentWorkflowStartedWireFormat = KeysToSnakeCase<AiAgentWorkflowStarted>;

interface AiAgentTaskStarted {
  title: string;
}
type AiAgentTaskStartedWireFormat = KeysToSnakeCase<AiAgentTaskStarted>;

interface AiAgentTaskFinished {
  title: string;
}
type AiAgentTaskFinishedWireFormat = KeysToSnakeCase<AiAgentTaskFinished>;

interface AiAgentTaskFailed {
  title: string;
  reason?: string;
}
type AiAgentTaskFailedWireFormat = KeysToSnakeCase<AiAgentTaskFailed>;

interface AiAgentTaskStepReasoning {
  step_number: string;
  step_description: string;
  reason: string;
}
type AiAgentTaskStepReasoningWireFormat = AiAgentTaskStepReasoning;

interface AiAgentSetAttribute {
  name: string;
  value: string;
}
type AiAgentSetAttributesWireFormat = AiAgentSetAttribute[];

interface EmailVerificationSuccessful {
  title: string;
}
type EmailVerificationSuccessfulWireFormat = KeysToSnakeCase<EmailVerificationSuccessful>;

interface EmailVerificationFailed {
  title: string;
}
type EmailVerificationFailedWireFormat = KeysToSnakeCase<EmailVerificationFailed>;

interface EmailAlreadyVerified {
  title: string;
}
type EmailAlreadyVerifiedWireFormat = KeysToSnakeCase<EmailAlreadyVerified>;

interface EmailTriggeredForVerification {
  title: string;
  actionId: number;
}
type EmailTriggeredForVerificationWireFormat = KeysToSnakeCase<EmailTriggeredForVerification>;

export default class OperatorWorkflowEventData {
  readonly admin?: AdminSummary;
  readonly waitStarted?: WaitStarted;
  readonly skipAssignmentAction?: SkipAssignmentAction;
  readonly workflowFinished?: WorkflowFinished;
  readonly actionData?: ActionData;
  readonly userInactivityConversationAutoClosed?: UserInactivityConversationAutoClosed;
  readonly languageOverrideUpdated?: LanguageOverrideUpdated;
  readonly aiAgentIgnoringUserMessage?: AiAgentIgnoringUserMessage;
  readonly aiAgentWorkflowStarted?: AiAgentWorkflowStarted;
  readonly aiAgentTaskStarted?: AiAgentTaskStarted;
  readonly aiAgentTaskFinished?: AiAgentTaskFinished;
  readonly aiAgentTaskFailed?: AiAgentTaskFailed;
  readonly aiAgentSetAttributes?: AiAgentSetAttributesWireFormat;
  readonly aiAgentTaskStepReasoning?: AiAgentTaskStepReasoningWireFormat;
  readonly emailVerificationSuccessful?: EmailVerificationSuccessful;
  readonly emailVerificationFailed?: EmailVerificationFailed;
  readonly emailAlreadyVerified?: EmailAlreadyVerified;
  readonly emailTriggeredForVerification?: EmailTriggeredForVerification;
  constructor(
    admin?: AdminSummary,
    waitStarted?: WaitStarted,
    skipAssignmentAction?: SkipAssignmentAction,
    workflowFinished?: WorkflowFinished,
    actionData?: ActionData,
    languageOverrideUpdated?: LanguageOverrideUpdated,
    userInactivityConversationAutoClosed?: UserInactivityConversationAutoClosed,
    aiAgentIgnoringUserMessage?: AiAgentIgnoringUserMessage,
    aiAgentWorkflowStarted?: AiAgentWorkflowStarted,
    aiAgentTaskStarted?: AiAgentTaskStarted,
    aiAgentTaskFinished?: AiAgentTaskFinished,
    aiAgentTaskFailed?: AiAgentTaskFailed,
    aiAgentSetAttributes?: AiAgentSetAttribute[],
    aiAgentTaskStepReasoning?: AiAgentTaskStepReasoning,
    emailVerificationSuccessful?: EmailVerificationSuccessful,
    emailVerificationFailed?: EmailVerificationFailed,
    emailAlreadyVerified?: EmailAlreadyVerified,
    emailTriggeredForVerification?: EmailTriggeredForVerification,
  ) {
    this.admin = admin;
    this.waitStarted = waitStarted;
    this.skipAssignmentAction = skipAssignmentAction;
    this.workflowFinished = workflowFinished;
    this.actionData = actionData;
    this.languageOverrideUpdated = languageOverrideUpdated;
    this.userInactivityConversationAutoClosed = userInactivityConversationAutoClosed;
    this.aiAgentIgnoringUserMessage = aiAgentIgnoringUserMessage;
    this.aiAgentWorkflowStarted = aiAgentWorkflowStarted;
    this.aiAgentTaskStarted = aiAgentTaskStarted;
    this.aiAgentTaskFinished = aiAgentTaskFinished;
    this.aiAgentTaskFailed = aiAgentTaskFailed;
    this.aiAgentSetAttributes = aiAgentSetAttributes;
    this.aiAgentTaskStepReasoning = aiAgentTaskStepReasoning;
    this.emailVerificationSuccessful = emailVerificationSuccessful;
    this.emailVerificationFailed = emailVerificationFailed;
    this.emailAlreadyVerified = emailAlreadyVerified;
    this.emailTriggeredForVerification = emailTriggeredForVerification;
  }

  static deserialize(json: OperatorWorkflowEventDataWireFormat): OperatorWorkflowEventData {
    return new OperatorWorkflowEventData(
      json.admin ? AdminSummary.deserialize(json.admin) : undefined,
      json.wait_started
        ? {
            durationType: json.wait_started.duration_type,
            customDuration: json.wait_started.custom_duration,
          }
        : undefined,
      json.skip_assignment_action
        ? {
            outboundId: json.skip_assignment_action.outbound_id,
            outboundType: json.skip_assignment_action.outbound_type,
            outboundName: json.skip_assignment_action.outbound_name,
          }
        : undefined,
      json.workflow_finished
        ? {
            reason: json.workflow_finished.reason,
          }
        : undefined,
      json.action_data
        ? {
            tagId: json.action_data.tag_id,
            attributeIdentifier: json.action_data.attribute_identifier,
            value: json.action_data.value,
          }
        : undefined,
      json.language_override_updated
        ? {
            previousValue: json.language_override_updated.previous_value,
            newValue: json.language_override_updated.new_value,
          }
        : undefined,
      json.user_inactivity_conversation_autoclosed
        ? {
            endUserInactivityTimer:
              json.user_inactivity_conversation_autoclosed.end_user_inactivity_timer,
            identifier: json.user_inactivity_conversation_autoclosed.identifier,
          }
        : undefined,
      json.ai_agent_ignoring_user_message
        ? {
            reason: json.ai_agent_ignoring_user_message.reason,
          }
        : undefined,
      json.ai_agent_workflow_started
        ? {
            title: json.ai_agent_workflow_started.title,
            id: json.ai_agent_workflow_started.id,
            rulesetId: json.ai_agent_workflow_started.ruleset_id,
          }
        : undefined,
      json.ai_agent_task_started ? { title: json.ai_agent_task_started.title } : undefined,
      json.ai_agent_task_finished ? { title: json.ai_agent_task_finished.title } : undefined,
      json.ai_agent_task_failed
        ? {
            title: json.ai_agent_task_failed.title,
            reason: json.ai_agent_task_failed.reason,
          }
        : undefined,
      json.ai_agent_set_attributes ? json.ai_agent_set_attributes : undefined,
      json.ai_agent_task_step_reasoning ? json.ai_agent_task_step_reasoning : undefined,
      json.email_verification_successful
        ? { title: json.email_verification_successful.title }
        : undefined,
      json.email_verification_failed ? { title: json.email_verification_failed.title } : undefined,
      json.email_already_verified ? { title: json.email_already_verified.title } : undefined,
      json.email_triggered_for_verification
        ? {
            title: json.email_triggered_for_verification.title,
            actionId: json.email_triggered_for_verification.action_id,
          }
        : undefined,
    );
  }
}
