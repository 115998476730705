/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import { EntityType } from 'embercom/models/data/entity-types';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';
import { tracked } from '@glimmer/tracking';
import { Channel } from 'embercom/models/data/inbox/channels';

interface QuickReplyResponseWireFormat {
  renderable_type: RenderableType;
  text: string | null;
  user_summary: UserSummaryWireFormat;
  response_to_part_id: number;
  tags: Array<TagWireFormat>;
  channel?: Channel;
  url: string | null;
  ua: string | null;
  translations?: Record<string, string>;
  original?: string | null;
}

export default class QuickReplyResponse implements RenderableData {
  readonly renderableType = RenderableType.QuickReplyResponse;
  readonly text: string;
  readonly userSummary: UserSummary;
  readonly responseToPartId: number;
  @tracked tags: Array<Tag>;
  readonly channel: Channel;
  readonly url?: string;
  readonly ua?: string;
  readonly translations?: Record<string, string>;
  readonly original?: string;
  get creatingEntity() {
    return { type: EntityType.User, id: this.userSummary.id, object: this.userSummary };
  }

  get styles() {
    return {
      backgroundColor: 'bg-neutral-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-neutral-container-emphasis',
    };
  }

  get textContent() {
    if (this.text.match(/<|>|&/g)) {
      return htmlToTextContent(this.text);
    } else {
      return this.text;
    }
  }

  constructor(
    text: string,
    userSummary: UserSummary,
    responseToPartId: number,
    tags?: Array<Tag>,
    channel?: Channel,
    url?: string,
    ua?: string,
    translations?: Record<string, string>,
    original?: string,
  ) {
    this.text = text;
    this.userSummary = userSummary;
    this.responseToPartId = responseToPartId;
    this.tags = tags || [];
    this.channel = channel ?? Channel.Unknown;
    this.url = url;
    this.ua = ua;
    this.translations = translations || {};
    this.original = original || '';
  }

  static deserialize(json: QuickReplyResponseWireFormat): QuickReplyResponse {
    let userSummary = UserSummary.deserialize(json.user_summary);
    return new QuickReplyResponse(
      json.text || '',
      userSummary,
      json.response_to_part_id,
      json.tags && json.tags.map(Tag.deserialize),
      json.channel,
      json.url || undefined,
      json.ua || undefined,
      json.translations || {},
      json.original || undefined,
    );
  }
}
