/* RESPONSIBLE TEAM: team-workflows */

import EditorConfig, {
  type PathConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { UserInactivityPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import type MutableArray from '@ember/array/mutable';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';

export default class UserInactivityEditorConfig extends EditorConfig {
  public targetChannels: MutableArray<string>;

  constructor({
    targetChannels,
    areStepPaywallsActive,
    workflow,
  }: {
    targetChannels: MutableArray<string>;
    areStepPaywallsActive: boolean;
    workflow?: Workflow;
  }) {
    super({ areStepPaywallsActive, workflow });
    this.targetChannels = targetChannels;
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new UserInactivityPathConfig({
      path,
      editorState,
      supportsLocalAttributes: this.supportsLocalAttributes,
    });
  }
}
