/* RESPONSIBLE TEAM: team-standalone */
import EditorConfig, {
  type PathConfigGenerationParams,
  type StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { NewZendeskTicketPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import type Step from 'embercom/models/operator/visual-builder/step';
import { StandaloneChatMessageConfiguration } from 'embercom/objects/visual-builder/configuration/step/chat-message';
import { StandaloneTicketFinStepConfig } from 'embercom/objects/visual-builder/configuration/step/fin';
import { StandaloneAttributeCollectorConfiguration } from 'embercom/objects/visual-builder/configuration/step/attribute-collector';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import HandoffToZendeskAgentConfiguration from '../step/handoff-to-zendesk-agent';
import { NEW_ZENDESK_TICKET_TARGET } from 'embercom/lib/operator/custom-bots/constants';

export default class NewZendeskTicketBotConfig extends EditorConfig {
  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new NewZendeskTicketPathConfig({
      path,
      editorState,
      supportsLocalAttributes: this.supportsLocalAttributes,
    });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.attributeCollector:
        return new StandaloneAttributeCollectorConfiguration(stepConfigArgs);
      case stepTypes.chatMessage:
        return new StandaloneChatMessageConfiguration({
          ...stepConfigArgs,
          attributeService: this.attributeService,
        });
      case stepTypes.fin:
        return new StandaloneTicketFinStepConfig(stepConfigArgs);
      case stepTypes.handoffToZendeskAgent:
        return new HandoffToZendeskAgentConfiguration({
          ...stepConfigArgs,
          tooltipCopy: this.intl.t(
            'operator.workflows.visual-builder.step-menu.handoff-to-zendesk-agent-tooltip-zd-tickets',
          ),
          workflowTarget: NEW_ZENDESK_TICKET_TARGET,
        });
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }

  get supportsLocalAttributes(): boolean {
    return true;
  }

  get supportsConversationChannelTargeting(): boolean {
    return false;
  }

  get supportsSideSheet(): boolean {
    return false;
  }
}
