/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable no-restricted-imports */

import {
  type StepConfigGenerationParams,
  type PathConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import type Step from 'embercom/models/operator/visual-builder/step';
import type StepConfig from '../step';
import { stepTypes } from '../../configuration-list';
import { TargetContextAwareNoteConfiguration } from '../step/note';
import TargetContextEditorConfig from './target-context-editor-config';
import { AdminNoteCreatedPathConfig } from '../path';

export default class AdminNoteCreatedEditorConfig extends TargetContextEditorConfig {
  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new AdminNoteCreatedPathConfig({
      path,
      editorState,
      supportsLocalAttributes: this.supportsLocalAttributes,
      targetContexts: this.targetContexts,
    });
  }

  generateStepConfig<S extends Step>({
    step,
    pathConfig,
  }: StepConfigGenerationParams<S>): StepConfig {
    let stepConfigArgs = { step, pathConfig };

    if (step.typeKey === stepTypes.note) {
      return new TargetContextAwareNoteConfiguration({
        ...stepConfigArgs,
        attributeService: this.attributeService,
      });
    }

    return super.generateStepConfig(stepConfigArgs);
  }
}
