/* RESPONSIBLE TEAM: team-knowledge-foundations */

import KnowledgeHubItemMixin, {
  type KnowledgeHubItem,
} from 'embercom/models/content-service/knowledge-hub-item';
import Model from '@ember-data/model';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import {
  moveToFolderAndSave,
  type MembershipChild,
} from 'embercom/objects/content-service/folders/membership-child';
import type Folder from 'embercom/models/content-service/folder';
import type FolderMembership from 'embercom/models/content-service/folder-membership';
import {
  type LocalizedKnowledgeContent,
  LocalizedKnowledgeContentMixin,
} from 'embercom/objects/knowledge-hub/localized-knowledge-content';

/**
 * Interface for any content item that is available in the Knowledge Hub.
 * If implementing this interface, you should probably also extend the KnowledgeHubContentMixin or MonolingualKnowledgeContentModel below
 * Documentation for this interface can be found in the definitions of the interfaces it extends.
 */
export interface KnowledgeHubContent extends KnowledgeHubItem, MembershipChild {}

type Constructable = new (...args: any[]) => object;

/**
 * Provides some default implementation of the `KnowledgeHubContent` interface.
 * Where appropriate, you should use the `MonolingualKnowledgeContentModel` class below, which extends this mixin.
 */
export default function KnowledgeHubContentMixin<BC extends Constructable>(Base: BC) {
  abstract class KnowledgeHubContentMixin
    extends KnowledgeHubItemMixin(LocalizedKnowledgeContentMixin(Base)) // TODO: This should not extend LocalizedKnowledgeContentMixin.
    implements KnowledgeHubContent
  {
    /** The property that is used to store the folder membership. This will usually be a sync belongsTo association. */
    declare folderMembership?: FolderMembership;

    async moveToFolderAndSave({
      folder,
      disableConfirmation,
    }: {
      folder?: Folder;
      disableConfirmation?: boolean;
    }): Promise<void> {
      return moveToFolderAndSave({
        contentItem: this,
        destinationFolder: folder,
        disableConfirmation,
      });
    }

    get entityIdForMembership() {
      return this.entityId;
    }

    get entityTypeForMembership() {
      return this.entityType;
    }
  }
  return KnowledgeHubContentMixin;
}

/**
 * This takes the above mixin and extends it with the Ember Data Model class.
 * Allows us to use default Ember Data model methods and properties to provide some of the functionality.
 * It is not be possible to use this in all cases, so where possible please add method implementations to the mixin above.
 * This is not intended for use for multilingual content types.
 */
export abstract class MonolingualKnowledgeContentModel
  extends KnowledgeHubContentMixin(Model)
  implements KnowledgeHubContent, LocalizedKnowledgeContent
{
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || Object.keys(this.changedAttributes()).length > 0;
  }

  get saving() {
    return this.isSaving;
  }

  get entityId(): number {
    return Number(this.id);
  }

  get parentContent(): KnowledgeHubContent {
    return this;
  }

  get requiredEditPermissionForKnowledgeHub() {
    return CAN_MANAGE_KNOWLEDGE_BASE_CONTENT;
  }

  get requiredPublishPermissionForKnowledgeHub() {
    return CAN_MANAGE_KNOWLEDGE_BASE_CONTENT;
  }

  get requiredDeletePermissionForKnowledgeHub() {
    return CAN_MANAGE_KNOWLEDGE_BASE_CONTENT;
  }
}
