/* RESPONSIBLE TEAM: team-knowledge-foundations */

import {
  type DropActionType,
  DefaultTreeSettings,
  Tree,
  TreeItem,
  type TreeParent,
} from 'embercom/objects/tree-list';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Folder from 'embercom/models/content-service/folder';
import {
  didDropItem,
  canDropItem,
  exceedsMaxLevel,
  MAX_DEPTH,
  folderIsActiveOnRoute,
  createTreeItem,
  orderedRootFolders,
  FolderTreeSettings,
  indexForDropInside,
} from 'embercom/components/knowledge-hub/folders/tree-helper';
import { type KnowledgeHubItem } from 'embercom/models/content-service/knowledge-hub-item';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import {
  IMPORT_JOB_DELETE_STATUS_ID,
  IMPORT_JOB_STATUS_EVENT_ID,
  ARTICLE_SYNC_SETTINGS_DELETED,
  ARTICLE_IMPORT_COMPLETED,
  type ImportServiceImportSourceDeletedEvent,
  type UpdateImportSourceStatusEvent,
  type BulkActionProgressionEvent,
  BulkActionProgressionValues,
  BULK_ACTION_PROGRESSION_ID,
  BulkActionType,
} from 'embercom/services/knowledge-hub-service';
import { IMPORT_SOURCE_STATUSES } from 'embercom/lib/knowledge-hub/constants';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import { type RouteInfoWithAttributes } from '@ember/routing/route-info';
import { setOwner } from '@ember/application';
import type ApplicationInstance from '@ember/application/instance';

export default class KnowledgeTree {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare router: RouterService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare intercomEventService: $TSFixMe;

  @tracked tree: Tree;
  @tracked activeItem: TreeItem<Folder> | undefined;
  @tracked foldersLoading = false;

  constructor(owner: ApplicationInstance) {
    setOwner(this, owner);

    this.tree = new Tree(
      [],
      {
        canDragItem: (item) => this.itemIsDraggable(item),
        canDropItem,
        didDropItem: async (
          item: TreeItem,
          oldParent: TreeParent,
          dropActionType: DropActionType,
        ) => {
          await didDropItem(item, oldParent, dropActionType, {
            intercomEventService: this.intercomEventService,
          });
        },
        willDropItem: (item: TreeItem, newParent: TreeParent, _: DropActionType) => {
          let isEditable = this.itemIsEditable(item) || item.dataObject.isRoot;
          if (!isEditable) {
            this.notificationsService.notifyError(
              this.intl.t('knowledge-hub.folders.error-folder-synced'),
            );
            return false;
          }
          let shouldDrop = !exceedsMaxLevel(newParent, item);
          if (!shouldDrop) {
            this.notificationsService.notifyError(
              this.intl.t('knowledge-hub.folders.error-max-depth', {
                maxDepth: MAX_DEPTH,
              }),
            );
          }
          return shouldDrop;
        },
        indexForDropInside,
      },
      {
        ...DefaultTreeSettings,
        ...FolderTreeSettings,
      },
    );

    this.tree.animationDuration = 0;

    this.loadFolderTree();

    this.setupRouteTracking();

    this.setImportServiceUpdateActions();
    this.setArticleSyncSettingDeletedActions();
    this.setArticleImportCompletedActions();
    this.setBulkMoveToFolderCompletedActions();
  }

  itemIsDraggable(item: TreeItem): boolean {
    return this.knowledgeHubService.currentAdminCanManageContent && this.itemIsEditable(item);
  }

  setImportServiceUpdateActions() {
    this.realTimeEventService.on(IMPORT_JOB_STATUS_EVENT_ID, this, 'forceLoadFolderTreeOnSuccess');
    this.realTimeEventService.on(
      IMPORT_JOB_DELETE_STATUS_ID,
      this,
      'forceLoadFolderTreeOnDeletion',
    );
  }

  setArticleSyncSettingDeletedActions() {
    this.realTimeEventService.subscribeTopics(['article-sync-settings-deleted']);
    this.realTimeEventService.on(ARTICLE_SYNC_SETTINGS_DELETED, this, 'forceLoadFolderTree');
  }

  setArticleImportCompletedActions() {
    this.realTimeEventService.subscribeTopics(['import-completed']);
    this.realTimeEventService.on(ARTICLE_IMPORT_COMPLETED, this, 'loadFolderTree');
  }

  setBulkMoveToFolderCompletedActions() {
    this.realTimeEventService.on(
      BULK_ACTION_PROGRESSION_ID,
      this,
      'forceLoadFolderTreeOnBulkMoveToFolder',
    );
  }

  async forceLoadFolderTreeOnSuccess(event: UpdateImportSourceStatusEvent) {
    if (event.status === IMPORT_SOURCE_STATUSES.success) {
      await this.loadFolderTree(true);
    }
  }

  async forceLoadFolderTreeOnDeletion(event: ImportServiceImportSourceDeletedEvent) {
    if (event.is_deleted) {
      await this.loadFolderTree(true);
    }
  }

  async forceLoadFolderTreeOnBulkMoveToFolder(event: BulkActionProgressionEvent) {
    if (
      event.bulk_action === BulkActionType.MOVE_TO_FOLDER &&
      event.status === BulkActionProgressionValues.Complete
    ) {
      await this.forceLoadFolderTree();
    }
  }

  async forceLoadFolderTree() {
    this.store.unloadAll('content-service/folder');
    await this.loadFolderTree(true);
  }

  @action async loadFolderTree(forceRefresh = false) {
    this.foldersLoading = true;
    let rootItem = this.createRootItem();
    this.tree.children = [rootItem];
    let children: TreeItem<Folder>[] = [];
    let orderedFolders = await orderedRootFolders({
      knowledgeHubService: this.knowledgeHubService,
      forceRefresh,
    });
    for (let folder of orderedFolders) {
      children.push(await this.createTreeItem(rootItem, folder));
    }
    rootItem.children = children;
    this.foldersLoading = false;
  }

  private createRootItem(): TreeItem {
    return new TreeItem<undefined>({
      tree: this.tree,
      parent: this.tree,
      children: [],
      isExpanded: true,
      canHaveChildren: true,
      dataObject: undefined,
      component: 'knowledge-hub/folders/tree/nav-list-item',
    });
  }

  async createTreeItem(parent: TreeParent, folder: Folder) {
    let treeItem = createTreeItem(parent, folder) as TreeItem<Folder>;
    folder.treeItem = treeItem;
    let currentRoute = this.router?.currentRoute as RouteInfoWithAttributes | undefined;
    if (
      currentRoute?.attributes &&
      (await folderIsActiveOnRoute(currentRoute?.attributes, folder))
    ) {
      this.knowledgeHubService.setActiveItem(treeItem);
    }
    if (folder.hasChildren) {
      let { children, hasSelectedChild } = await this.createChildTreeItems(treeItem, folder);
      treeItem.addChildren(children);
      treeItem.isExpanded = hasSelectedChild;
    }
    return treeItem;
  }

  async createChildTreeItems(parent: TreeParent, folder: Folder) {
    let children: TreeItem<Folder>[] = [];
    let hasSelectedChild = false;
    for (let subFolder of folder.orderedSubFolders) {
      let childTreeItem = await this.createTreeItem(parent, subFolder);
      children.push(childTreeItem);
      //check if child is expanded or if the current route is the child
      if (childTreeItem.isExpanded || childTreeItem.isActive) {
        hasSelectedChild = true;
      }
    }
    return { children, hasSelectedChild };
  }

  async setupRouteTracking(): Promise<void> {
    this.router.on('routeDidChange', async () => {
      this.knowledgeHubService.updateActiveItem();
    });
  }

  itemIsEditable(item: TreeItem<KnowledgeHubItem>) {
    return item.dataObject?.isEditable;
  }
}
