/* RESPONSIBLE TEAM: team-tickets-1 */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import { type CustomActionStartedWireFormat } from 'embercom/objects/inbox/action-name-summary';
import CustomBotSummary from '../custom-bot-summary';

export default class CustomActionStarted implements RenderableData {
  renderableType: RenderableType = RenderableType.CustomActionStarted;
  readonly adminSummary: AdminSummary;
  readonly customActionName: string;
  readonly customBotSummary?: CustomBotSummary;
  readonly customActionId?: number;
  readonly finPreviewConversation?: boolean;
  readonly workflowPreviewConversation?: boolean;

  get creatingEntity() {
    return {
      type: EntityType.Bot,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  constructor(
    adminSummary: AdminSummary,
    customActionName: string,
    customBotSummary?: CustomBotSummary,
    customActionId?: number,
    finPreviewConversation?: boolean,
    workflowPreviewConversation?: boolean,
  ) {
    this.adminSummary = adminSummary;
    this.customActionName = customActionName;
    this.customBotSummary = customBotSummary;
    this.customActionId = customActionId;
    this.finPreviewConversation = finPreviewConversation;
    this.workflowPreviewConversation = workflowPreviewConversation;
  }

  static deserialize(json: CustomActionStartedWireFormat): CustomActionStarted {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    return new CustomActionStarted(
      adminSummary,
      json.custom_action_name,
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
      json.custom_action_id,
      json.fin_preview_conversation,
      json.workflow_preview_conversation,
    );
  }
}
