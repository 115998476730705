/* RESPONSIBLE TEAM: team-workflows */

import EditorConfig, {
  type PathConfigGenerationParams,
  type StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { NewPhoneCallTargetPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import type MutableArray from '@ember/array/mutable';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';
// Step configuration classes
import { PhoneTriggerReplyButtonsConfiguration } from 'embercom/objects/visual-builder/configuration/step/reply-buttons';
import { NewPhoneCallChatMessageConfiguration } from 'embercom/objects/visual-builder/configuration/step/chat-message';
import { PhoneTriggerAssignConversationConfiguration } from 'embercom/objects/visual-builder/configuration/step/assign-conversation';
import type ReplyButtons from 'embercom/models/operator/visual-builder/step/reply-buttons';
import type Step from 'embercom/models/operator/visual-builder/step';
import type ChatMessage from 'embercom/models/operator/visual-builder/step/chat-message';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';

export default class NewPhoneCallTargetEditorConfig extends EditorConfig {
  public targetChannels: MutableArray<string>;

  constructor({
    targetChannels,
    areStepPaywallsActive,
    workflow,
  }: {
    targetChannels: MutableArray<string>;
    areStepPaywallsActive: boolean;
    workflow?: Workflow;
  }) {
    super({ areStepPaywallsActive, workflow });
    this.targetChannels = targetChannels;
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new NewPhoneCallTargetPathConfig({
      path,
      editorState,
      supportsLocalAttributes: this.supportsLocalAttributes,
    });
  }

  generateStepConfig<S extends ReplyButtons>(
    params: StepConfigGenerationParams<S>,
  ): PhoneTriggerReplyButtonsConfiguration;
  generateStepConfig<S extends ChatMessage>(
    params: StepConfigGenerationParams<S>,
  ): NewPhoneCallChatMessageConfiguration;
  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.chatMessage:
        return new NewPhoneCallChatMessageConfiguration({
          ...stepConfigArgs,
          attributeService: this.attributeService,
        });
      case stepTypes.replyButtons:
        return new PhoneTriggerReplyButtonsConfiguration(stepConfigArgs);
      case stepTypes.assignConversation:
        return new PhoneTriggerAssignConversationConfiguration(stepConfigArgs);
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }

  get supportsConversationChannelTargeting(): boolean {
    return false;
  }
}
