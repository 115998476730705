/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import { EntityType } from 'embercom/models/data/entity-types';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';
import { type Channel } from 'embercom/models/data/inbox/channels';
import { tracked } from '@glimmer/tracking';
import { Upload, type UploadWireFormat } from 'embercom/objects/inbox/renderable/upload';
import CustomBotSummary, {
  type CustomBotSummaryWireFormat,
} from 'embercom/objects/inbox/custom-bot-summary';

export enum UserContentSeenState {
  Unseen = 'unseen',
  Seen = 'seen',
}

interface UserCommentWireFormat {
  renderable_type: RenderableType;
  blocks: Array<object>;
  user_summary: UserSummaryWireFormat;
  tags: Array<TagWireFormat>;
  channel: Channel;
  url: string | null;
  ua: string | null;
  uploads?: Array<UploadWireFormat>;
  custom_bot_summary?: CustomBotSummaryWireFormat;
  seen_by_current_admin?: UserContentSeenState;
  translations?: Record<string, string>;
  original?: string | null;
  text?: string | null;
}

export default class UserComment implements RenderableData {
  readonly renderableType = RenderableType.UserComment;
  readonly blocks: Array<object>;
  readonly userSummary: UserSummary;
  @tracked tags: Array<Tag>;
  readonly channel: Channel;
  readonly url?: string;
  readonly ua?: string;
  readonly uploads?: Array<Upload>;
  readonly customBotSummary?: CustomBotSummary;
  seenByCurrentAdmin?: UserContentSeenState;
  readonly translations?: Record<string, string>;
  readonly original?: string | null;
  readonly text?: string | null;
  get creatingEntity() {
    return { type: EntityType.User, id: this.userSummary.id, object: this.userSummary };
  }

  get styles() {
    return {
      backgroundColor: 'bg-neutral-container',
      textColor: 'text-default',
      backgroundColorHover: 'bg-neutral-container-emphasis',
    };
  }

  setSeenByCurrentAdmin(seen: UserContentSeenState): void {
    this.seenByCurrentAdmin = seen;
  }

  constructor(
    blocks: Array<object>,
    userSummary: UserSummary,
    tags: Array<Tag>,
    channel: Channel,
    url?: string,
    ua?: string,
    uploads?: Array<Upload>,
    customBotSummary?: CustomBotSummary,
    seenByCurrentAdmin?: UserContentSeenState,
    translations?: Record<string, string>,
    original?: string | null,
    text?: string | null,
  ) {
    this.blocks = blocks;
    this.userSummary = userSummary;
    this.tags = tags || [];
    this.channel = channel;
    this.url = url;
    this.ua = ua;
    this.uploads = uploads || [];
    this.customBotSummary = customBotSummary;
    this.seenByCurrentAdmin = seenByCurrentAdmin;
    this.translations = translations || {};
    this.original = original;
    this.text = text;
  }

  static deserialize(json: UserCommentWireFormat): UserComment {
    let userSummary = UserSummary.deserialize(json.user_summary);
    let customBotSummary = undefined;
    if (json.custom_bot_summary) {
      customBotSummary = CustomBotSummary.deserialize(json.custom_bot_summary);
    }
    return new UserComment(
      json.blocks,
      userSummary,
      json.tags && json.tags.map(Tag.deserialize),
      json.channel,
      json.url || undefined,
      json.ua || undefined,
      json.uploads?.map(Upload.deserialize),
      customBotSummary,
      json.seen_by_current_admin,
      json.translations || {},
      json.original || undefined,
      json.text || undefined,
    );
  }
}
