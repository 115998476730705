/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable no-restricted-imports */

import type AttributeCollector from 'embercom/models/operator/visual-builder/step/attribute-collector';
import StepConfig, { type StepConfigParams } from '../step';

export default class AttributeCollectorConfiguration extends StepConfig {
  declare step: AttributeCollector;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/attribute-collector',
      ...params,
    });
  }

  deleteStep() {
    if (this.step.choiceAttributeDescriptor) {
      this.editorState.deleteLocalVariable(this.step.choiceAttributeDescriptor);
    }
    super.deleteStep();
  }

  /*
   * Controls whether the attribute creation modal should be accessible from the attribute collector step.
   */
  get allowAttributeCreation() {
    return true;
  }

  /*
   * Controls whether the local variable creation modal should be accessible from the attribute collector step.
   */
  get allowLocalVariableCreation() {
    return this.supportsLocalAttributes;
  }
}

export class StandaloneAttributeCollectorConfiguration extends AttributeCollectorConfiguration {
  get allowAttributeCreation() {
    return false;
  }
}
